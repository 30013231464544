import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { CircuitService } from 'src/app/services/circuit.service';
import { EntrepriseService } from "../../services/entreprise.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-circuit',
  templateUrl: './circuit.component.html',
  styleUrls: ['./circuit.component.css']
})
export class CircuitComponent implements OnInit, OnChanges {
  @ViewChild('actionCircuit') actionCircuit: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  public listCircuit: any = [];
  public listEntreprise: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public circuitId: any;
  public circuitNumber: any;
  public circuitName: any;
  public circuitComment: any;
  public idEntreprise: any;
  public entrepriseType : any;
  // check action
  public checkAction: any;

  constructor(
    private circuitService: CircuitService,
    private entrepriseService: EntrepriseService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.entrepriseType = localStorage.getItem('entrepriseType');
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    if(this.entrepriseType == "1"){
      this.circuitService.getCircuits()
      .subscribe((res: any) => {
        this.listCircuit = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
    }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.circuitService.getCircuitsByEntreprise(body)
        .subscribe((res: any) => {
          this.listCircuit = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
    }

      this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data
      })
  }

  ngOnChanges(): void {
    this.loadCircuits()
  }

  public loadCircuits(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.circuitService.getCircuits()
        .subscribe((res: any) => {
          this.listCircuit = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.circuitService.getCircuitsByEntreprise(body)
          .subscribe((res: any) => {
            this.listCircuit = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }

        this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data
      })
    })
  }

  public async emitDataCircuits(item: any) {
    this.checkAction = 'update'
    console.log('emit data for action', item)
    this.emitData.emit(item);
    this.circuitId = item.id;
    this.circuitName = item.name;
    this.circuitNumber = item.number;
    this.circuitComment = item.comment;
    this.idEntreprise = item.entrepriseId;
    await <any>this.ngbModal.open(this.actionCircuit)
  }

  /**
  * beforeDelete
  */
  public beforeDelete(itemId: any) {
    this.circuitId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  public addCircuits() {
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      number: this.circuitNumber,
      name: this.circuitName,
      comment: this.circuitComment,
      entrepriseId: id_E,
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.circuitService.addCircuits(body)
      .subscribe((res: any) => {
        this.loadCircuits();
        this.resetField();
        this.spinner.hide();
      })
  }

  public updateCircuits(): void{
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      id: this.circuitId,
      number: this.circuitNumber,
      name: this.circuitName,
      comment: this.circuitComment,
      entrepriseId: id_E,
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.circuitService.updateCircuits(body)
      .subscribe((res: any) => {
        this.loadCircuits();
        this.resetField();
        this.spinner.hide();
      })
  }

  public deleteCircuits() {
    let body = {
      id: this.circuitId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.circuitService.deleteCircuits(body)
      .subscribe((res: any) => {
        this.loadCircuits();
        this.resetField();
        this.spinner.hide();
      })
  }

  public openModal(modal) {
    this.checkAction = 'add';
    this.ngbModal.open(modal)
  }

  public resetField() {
    this.ngbModal.dismissAll();
    this.circuitNumber = '';
    this.circuitName = '';
    this.circuitComment = '';
    this.idEntreprise = ''
  }
}
