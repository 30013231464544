import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { AnomalyService } from '../../services/anomaly.service';
import { EntrepriseService } from "../../services/entreprise.service";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-anomaly',
  templateUrl: './anomaly.component.html',
  styleUrls: ['./anomaly.component.css']
})
export class AnomalyComponent implements OnInit, OnChanges {
  @ViewChild('actionAnomaly') actionAnomaly: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listAnomalies: any = [];
  public listEntreprise: any = [];

  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();

  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;

  // variable base
  public anomalyId: any;
  public anomalyDescription: any;
  public anomalyComment: any;
  public idEntreprise: any;

  public entrepriseType:any;

  // check action
  public checkAction: any


  constructor(
    private anomalyService: AnomalyService,
    private entrepriseService: EntrepriseService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.entrepriseType = localStorage.getItem('entrepriseType');
    if(this.entrepriseType === "1"){
      this.anomalyService.getAnomalies()
      .subscribe((res: any) => {
        this.listAnomalies = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
    }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      }
      this.anomalyService.getAnomaliesByEntreprise(body)
        .subscribe((res: any) => {
          this.listAnomalies = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
    }

      this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data
      })
  }

  ngOnChanges(): void {
    this.loadAnomaly()
  }


  /**
   * loadAnomaly
   */
  public loadAnomaly(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType === "1"){
        this.anomalyService.getAnomalies()
        .subscribe((res: any) => {
          this.listAnomalies = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.anomalyService.getAnomaliesByEntreprise(body)
          .subscribe((res: any) => {
            this.listAnomalies = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }

  /**
   * emitDataAnomalies
   */
  public async emitDataAnomalies(item: any) {
    this.checkAction = 'update';
    this.emitData.emit(item);
    this.anomalyId = item.id;
    this.anomalyDescription = item.description;
    this.anomalyComment = item.comment;
    this.idEntreprise = item.entrepriseId;
    await <any>this.ngbModal.open(this.actionAnomaly)
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.anomalyId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  /**
   * addAnomaly
   */
  public addAnomaly() {
    let id_E = "";
    if(this.entrepriseType === "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      description: this.anomalyDescription,
      comment: this.anomalyComment,
      entrepriseId: id_E,
    }

    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.anomalyService.addAnomaly(body)
      .subscribe((res: any) => {
        this.loadAnomaly();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * updateAnomaly
   */
  public updateAnomaly(): void{
    let id_E = "";
    if(this.entrepriseType === "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      id: this.anomalyId,
      description: this.anomalyDescription,
      comment: this.anomalyComment,
      entrepriseId: id_E,
    };
    this.anomalyService.updateAnomaly(body)
      .subscribe((res: any) => {
        this.loadAnomaly();
        this.resetField()
      })
  }

  /**
   * deleteAnomaly
   */
  public deleteAnomaly() {
    let body = {
      id: this.anomalyId
    }

    this.anomalyService.deleteAnomaly(body)
      .subscribe((res: any) => {
        this.loadAnomaly();
        this.resetField()
      })
  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.checkAction = 'add';
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll();
    this.anomalyDescription = '';
    this.anomalyComment = '';
    this.idEntreprise = ''
  }
}
