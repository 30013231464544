
<!--    <script src="../../../../../../gitclonebomelman_nodeadmin/wast-git/controllers/email.js"></script>-->
    <script src="rapportleveebac.component.ts"></script>
    <div *ngIf="a === false">
      <div style="" class="btn-periode" role="group" aria-label="Basic example">
        <div style="display:flex; flex-direction: row">
        <button style="margin-left: 15px; margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(hier,hier)">Hier</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_mois, fin_mois)">M</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="generatePdf('print')">Imprimer</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="afficherBy2Date(debut,fin)">Afficher</button>
        <div style="display:flex; flex-direction: row">
        <div mdbDropdown class="dropdown">
        <button style="margin-right: 5px;" class="btn btn-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" >
          Télécharger
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="generatePdf('download')">Fichier PDF</a>
          <a class="dropdown-item" (click)="generateExcel()">Fichier Excel</a>
        </div>
        </div>
        <div mdbDropdown1 class="dropdown1">
        <button style="margin-right: 5px;" class="btn btn-info btn-sm dropdown-toggle" type="button" id="kaka" data-toggle="dropdown" >
          {{bomforfiltre == ''?'Bom': bomforfiltre}}
        </button>
        <div  class="dropdown-menu" aria-labelledby="kaka" >
          <span  *ngFor="let item of listBom">
            <a class="dropdown-item" (click)="choisirbomfilter(item.immatriculation)">{{item.immatriculation}}</a>
          </span>
        </div>
        </div>
        </div>


        <input style="margin-right: 5px;" id="date" type="date" value="" name="debut" [(ngModel)]="debut">
        <input style="margin-right: 5px;" id="heure" type="time" value="" name="H debut" [(ngModel)]="Hdebut">
        <input style="margin-right: 5px;" id="date1" type="date" value="" name="fin" [(ngModel)]="fin">
        <input style="margin-right: 5px;" id="heure1" type="time" value="" name="H fin" [(ngModel)]="Hfin">
      </div>

      </div>
        <div class="card-body">
          <div class="row">
                    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
                    <thead>
                        <tr>
                            <th></th>
                            <th *ngIf="entrepriseType == '1'">Entreprise</th>
                            <th>Heure / Date</th>
                            <th>BOM</th>
                            <th>Conducteur/N° Circuit</th>
                            <th>Numéro de Bac</th>
                            <th>Lieu de Bac collecté</th>
                            <th>Coordonées GPS Bac</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of listRapportBac">
                          <td >
                            <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieursRapportBac(item?.id, $event)" >
                          </td>
                          <td *ngIf="entrepriseType == '1'">{{item.entrepriseId != null ? item.entreprise.name:'----'}}</td>
                          <td>{{item?.heuredateformated | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                          <td>{{item?.bom != null ? item?.bom : '----'}}</td>
                          <td>{{item?.conducteurcircuit != null ? item?.conducteurcircuit : '----'}}</td>
                          <td>{{item?.numerobac != null ? item.numerobac:'----'}}</td>
                          <td>{{item.lieubac != null ? item.lieubac:'----'}}</td>
                          <td><a href="javascript:void(0)" (click)="openMapbis(item.gps)">{{item.gps != null ? item.gps : '----'}}</a></td>

<!--                                                    <td>{{item | json}}</td>-->
                           <td>
                              <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
                                  <i class="fas fa-trash"></i>
                              </button>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieursRapportBac()">
            Supprimer les rapports cochés
          </button>
        </div>
    </div>

<div *ngIf="a === true"><span (click)="bb()"> bonjour </span></div>

<ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteRapportBac()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
</ng-template>

    <ng-template #confirmCheckDeletePlsRapportBac>
      <div class="card-body">
        Êtes-vous sûr de vouloir supprimer ces rapports?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieursRapportBac()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
      </div>
    </ng-template>

<ng-template #openCart>
    <div class="card-body">
        <div id="map" style="width:100%;height:400px"></div>
    </div>
</ng-template>
