import {Component, OnInit, EventEmitter, ViewChild, OnChanges, Inject, LOCALE_ID} from '@angular/core';
import { PointingService } from '../../services/pointing.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { PdfService } from '../../services/pdf.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from "rxjs";
import { DataTableDirective } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {NgxSpinnerService} from "ngx-spinner";
import {formatDate} from "@angular/common";
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-pointage',
  templateUrl: './pointage.component.html',
  styleUrls: ['./pointage.component.css']
})
export class PointageComponent implements OnInit {
  @ViewChild('confirmCheckDeletePlsPointages') confirmCheckDeletePlsPointages: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listPointing: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public entrepriseType : any;
  public collectId: any;
  public debut: any;
  public Hdebut: any;
  public fin: any;
  public Hfin: any;
  public aujourdui: any;
  public hier: any;
  public debut_semaine: any;
  public fin_semaine: any;
  public debut_mois: any;
  public fin_mois: any;
  public sec: any;
  public min: any;
  public hour: any;
  public tmp: any;
  private limit: number = 50;
  public periode: any;
  public emitData: EventEmitter<any> = new EventEmitter;
  public checkActiondemande: any;
  public checkEnableDisablePlusieursPointagesArray: any = [];


  constructor(
    private pointingService: PointingService,
    private ngbModal: NgbModal,
    private pdfService: PdfService,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) private locale: string

  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.aujourdui = this.pointingService.date_aujour_d_hui();
    this.hier = this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(),1);
    this.debut = this.pointingService.date_aujour_d_hui();
    this.fin = this.pointingService.date_aujour_d_hui();
    this.traitement_semaine();
    this.traitement_mois();
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 0, 'desc' ]]
    };

    this.entrepriseType = localStorage.getItem('entrepriseType');
    if(this.entrepriseType == "1"){
      this.pointingService.getPointings(this.limit)
      .subscribe((res: any) => {
        this.listPointing = res.data;
        this.spinner.hide();
        console.log('listPointing', this.listPointing);
        this.dtTigger.next()
      })
     }else{
      let body = { entrepriseId: localStorage.getItem('entrepriseId') };
      this.pointingService.getPointingsByEntreprise(body,this.limit)
        .subscribe((res: any) => {
          this.listPointing = res.data;
          this.spinner.hide();
          if(body.entrepriseId === "2"){
            this.Hdebut = '00:00';
            this.Hfin = '23:59';
            this.debut = this.pointingService.date_aujour_d_hui();
            this.fin = this.pointingService.date_aujour_d_hui();
          }else if(body.entrepriseId === "3"){
            this.Hdebut = '17:00';
            this.Hfin = '16:00';
            this.debut= moment.utc(this.pointingService.date_lendemain()).format("yyyy-MM-DD");
            this.fin = this.pointingService.date_aujour_d_hui();
          }
          this.dtTigger.next()
        })
     }
  }

  ngOnChanges(): void {
    this.loadPointings()
  }

  generatePdf(type) {
    this.pdfService.generatePdfPointage(this.listPointing,this.debut,this.fin,type, this.locale);
  }

  generateExcel(){
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    let id_E = "";
    if(this.entrepriseType == "1"){

    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let listPointageExcel = [];
    for (let i = 0 ; i < this.listPointing.length; i++){
      const item = this.listPointing[i];
      //88888888888888888888888888888888888888888888888888888888888888888888
      // var tttt = moment(item.createdAt).subtract(6, 'h');
      // item.createdAt = tttt;
      //8888888888888888888888888888888888888888888888888888888888888888888
        if(item.createdAtBegin !== null && item.createdAtBegin !== undefined ){
          // const transform1 = formatDate(item.createdAtBegin, 'HH:mm:ss', this.locale);
          // item.createdAtBegin = transform0 + ' ' + transform1 ;
          //88888888888888888888888888888888888888888888888888888888888888888
          // var tttt1 = moment(item.createdAt).subtract(6, 'h');
          //888888888888888888888888888888888888888888888888888888888888888888
          item.start = moment.utc(item.createdAt).format("HH:mm:ss");
        }
      if(item.createdAtEnd !== null && item.createdAtEnd !== undefined ) {
        //8888888888888888888888888888888888888888888888888888888888888888
        // var tttt2 = moment(item.updatedAt).subtract(6, 'h');
        //88888888888888888888888888888888888888888888888888888888888888888
        item.end = moment.utc(item.updatedAt).format("HH:mm:ss");
      }

      listPointageExcel.push(item);
    }
    let body = {
      listPointageExcel:listPointageExcel,
      id_E:id_E
    };

    this.pointingService.excel(body)
      .subscribe((res: any) => {
        if(res.status == 200){
          const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
          a.href = res.url;
          document.body.appendChild(a);
          a.click();
          this.loadPointings();
          this.resetField();
          this.spinner.hide();
          document.body.removeChild(a);
      }})
  }

  /**
   * loadPointings
   */
  public loadPointings(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.pointingService.getPointings(this.limit)
        .subscribe((res: any) => {
          this.listPointing = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
       }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.pointingService.getPointingsByEntreprise(body,this.limit)
          .subscribe((res: any) => {
            this.listPointing = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
       }
    })
  }






  public afficherBytoDate(start,end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          // endDate: this.add_day_date(end, 1)
          endDate: end_
        };
        this.pointingService.getPointingsBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listPointing = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_,
        };
        console.log("date", bodyE.startDate, bodyE.endDate)
        this.pointingService.getPointingsBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listPointing = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
      };
    })
  }






   public getBy2date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
       start_ = start + " " + this.Hdebut + ":00";
       end_ = end + " " + this.Hfin + ":00";
    }
     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.destroy();
    if(this.entrepriseType == "1"){
     let body = {
      startDate: start_,
      // endDate: this.add_day_date(end, 1)
      endDate: end_
    };
    this.pointingService.getPointingsBetweenTwoDates(body)
      .subscribe((res: any) => {
        this.listPointing = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
    }else{
      if(localStorage.getItem('entrepriseId') == "3"){
        let bodyE = {
        entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: moment.utc(start).format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment.utc(end).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.pointingService.getPointingsBetweenTwoDatesByEntreprise(bodyE)
        .subscribe((res: any) => {
          this.listPointing = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };
        console.log("date", start_, end_)
        this.pointingService.getPointingsBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listPointing = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
      };
      }
    })

   }



  public dat(dat1){
    const da1 = new Date(dat1);
    const dd = moment(da1).format('HH:mm:ss');
    return dd
  }

  public dateDiffbycreatedAt(date1, date2){
    if(date1 == undefined || date1 == null)
      return '----';
    if (date2 == undefined || date2 == null)
      return '----';

    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // @ts-ignore
    let difference = Math.abs(d1 - d2); //to get absolute value
    //calculate for each one
    let Days = Math.floor(difference / ( 1000 * 60 * 60 * 24 ));
    let Hours = Math.floor((difference / ( 1000 * 60 * 60 )) % 24);
    let Mins = Math.floor((difference / ( 1000 * 60 )) % 60);
    let Seconds = Math.floor((difference / ( 1000 )) % 60);
    return `${Hours.toString().padStart(2, '0')}:${Mins.toString().padStart(2, '0')}:${Seconds.toString().padStart(2, '0')}`;
  }


  public dateDiff(date1, date2){
    // date1 = new Date(date1);
    // date2 = new Date(date2);
    // Invalid date dia natao ity amban, const dd = moment.utc(moment.utc(date2,"DD/MM/YYYY HH:mm:ss").diff(moment.utc(date1,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
    const dd = moment(moment(moment(date2)).diff(moment(date1))).format("HH:mm:ss");
      return dd;
    // var diffMs = (date2 - date1); // milliseconds between now & Christmas
    // var diffDays = Math.floor(diffMs / 86400000); // days
    // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // var diffSecs = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 60000); // sec
    // console.log(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas 2009 =)");
    //
    // if(diffDays>0)
    // return diffDays + 'J ' + diffHrs + ':' + diffMins  + ':' + diffSecs
    // else
    //   return diffHrs + ':' + diffMins + ':' + diffSecs

  }
  //
  //  public dateDiff(date1, date2){
  //   date1 = new Date(date1);
  //   date2 = new Date(date2);
  //   this.tmp = date2 - date1;
  //   this.tmp = Math.floor(this.tmp/1000);
  //   this.sec = this.tmp % 60;
  //   this.tmp = Math.floor((this.tmp-this.sec)/60);
  //   this.min = this.tmp % 60;
  //   this.tmp = Math.floor((this.tmp-this.min)/60);
  //   this.hour = this.tmp % 24;
  //   if(this.hour<10){
  //     this.hour = '0'+this.hour
  //   }
  //   if(this.min<10){
  //     this.min = '0'+this.min
  //   }
  //   if(this.sec<10){
  //     this.sec = '0'+this.sec
  //   }
  //   return this.hour+':'+this.min+':'+this.sec;
  // }




  // public dateDiff1(date1, date2, itemstart, itemend){
  //   const hourstart = moment(itemstart).format("HH");
  //   const mnstart = moment(itemstart).format("mm");
  //   const secstart = moment(itemstart).format("ss");
  //   const hourend = moment(itemend).format("HH");
  //   const mnend = moment(itemend).format("mm");
  //   const secend = moment(itemend).format("ss");
  //
  //
  //   const HOUREN_ENLEVER = 7;
  //   const hourend_ = parseInt(hourend,10);
  //   let hourend_1 : number;
  //   const hourstart_ = parseInt(hourstart,10);
  //   let hourstart_1 : number;
  //   if(hourend_<24 && hourend_>16){
  //     hourend_1 = hourend_ - HOUREN_ENLEVER;
  //     hourstart_1 = hourstart_ - HOUREN_ENLEVER;
  //     const date1_ = this.debut + ' ' + hourstart_1 + ":" + mnstart + ":" + secstart;
  //     const date2_ = this.debut + ' ' + hourend_1 + ":" + mnend + ":" + secend;
  //     date1 = new Date(date1_);
  //     date2 = new Date(date2_);
  //     this.tmp = date2 - date1;
  //     this.tmp = Math.floor(this.tmp/1000);
  //     this.sec = this.tmp % 60;
  //     this.tmp = Math.floor((this.tmp-this.sec)/60);
  //     this.min = this.tmp % 60;
  //     this.tmp = Math.floor((this.tmp-this.min)/60);
  //     this.hour = this.tmp % 24;
  //     if(this.hour<10){
  //       this.hour = '0'+this.hour
  //     }
  //     if(this.min<10){
  //       this.min = '0'+this.min
  //     }
  //     if(this.sec<10){
  //       this.sec = '0'+this.sec
  //     }
  //   }else{
  //     date1 = new Date(date1);
  //     date2 = new Date(date2);
  //     this.tmp = date2 - date1;
  //     this.tmp = Math.floor(this.tmp/1000);
  //     this.sec = this.tmp % 60;
  //     this.tmp = Math.floor((this.tmp-this.sec)/60);
  //     this.min = this.tmp % 60;
  //     this.tmp = Math.floor((this.tmp-this.min)/60);
  //     this.hour = this.tmp % 24;
  //     if(this.hour<10){
  //       this.hour = '0'+this.hour
  //     }
  //     if(this.min<10){
  //       this.min = '0'+this.min
  //     }
  //     if(this.sec<10){
  //       this.sec = '0'+this.sec
  //     }
  //   }
  //   return this.hour+':'+this.min+':'+this.sec;
  // }

  public dateDiff1(date1, date2, hourstart, mnstart,secstart, hourend, mnend, secend){
    const HOUREN_ENLEVER = 7;
    const hourend_ = <number>hourend;
    let hourend_1 : number;
    const hourstart_ = <number>hourstart;
    let hourstart_1 : number;
    if(hourend_<24 && hourend_>16){
      hourend_1 = hourend_ - HOUREN_ENLEVER;
      hourstart_1 = hourstart_ - HOUREN_ENLEVER;
      const date1_ = this.debut + ' ' + hourstart_1 + ":" + mnstart + ":" + secstart;
      const date2_ = this.debut + ' ' + hourend_1 + ":" + mnend + ":" + secend;
      date1 = new Date(date1_);
      date2 = new Date(date2_);
      this.tmp = date2 - date1;
      this.tmp = Math.floor(this.tmp/1000);
      this.sec = this.tmp % 60;
      this.tmp = Math.floor((this.tmp-this.sec)/60);
      this.min = this.tmp % 60;
      this.tmp = Math.floor((this.tmp-this.min)/60);
      this.hour = this.tmp % 24;
      if(this.hour<10){
        this.hour = '0'+this.hour
      }
      if(this.min<10){
        this.min = '0'+this.min
      }
      if(this.sec<10){
        this.sec = '0'+this.sec
      }
    }else{
      date1 = new Date(date1);
      date2 = new Date(date2);
      this.tmp = date2 - date1;
      this.tmp = Math.floor(this.tmp/1000);
      this.sec = this.tmp % 60;
      this.tmp = Math.floor((this.tmp-this.sec)/60);
      this.min = this.tmp % 60;
      this.tmp = Math.floor((this.tmp-this.min)/60);
      this.hour = this.tmp % 24;
      if(this.hour<10){
        this.hour = '0'+this.hour
      }
      if(this.min<10){
        this.min = '0'+this.min
      }
      if(this.sec<10){
        this.sec = '0'+this.sec
      }
    }
    return this.hour+':'+this.min+':'+this.sec;
  }

  public traitement_semaine(){
    var ladate=new Date();
    var position = ladate.getDay();
    this.debut_semaine =  this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(), position);
    this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine,6);
  }

  public traitement_mois(){
    var mois = this.aujourdui.slice(5,7);
    var annees = this.aujourdui.slice(0,4);
    var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    this.debut_mois = this.aujourdui.slice(0,8)+'01';
    this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.collectId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * deletePointing
   */
  public deletePointing() {
    let body = {
      id: this.collectId
    };

    this.pointingService.deletePointings(body)
      .subscribe((res: any) => {
        this.loadPointings();
        this.resetField()
      })
  }






  public supprimerPlusieursPointages() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.pointingService.supprimerPlusieursPointages(this.checkEnableDisablePlusieursPointagesArray)
      .subscribe((res: any) => {
        this.loadPointings();
        this.resetField();
        this.spinner.hide()
      })
  }

  public openModaPourSupprimerPlusieursPointages() {
    this.checkActiondemande = 'add';
    this.ngbModal.open(this.confirmCheckDeletePlsPointages)
  }

  public checkEnableDisablePlusieursPointages(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursPointagesArray.length; i++){
        if(this.checkEnableDisablePlusieursPointagesArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursPointagesArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursPointagesArray = this.checkEnableDisablePlusieursPointagesArray.filter(function (item) {
        return item !== id;
      });
    }
  }

  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
  }





















  // handleDatePicked = date => {
  //   let hour_ = date.getHours().toString().padStart(2, "0");
  //   let minute_ = date.getMinutes().toString().padStart(2, "0");
  //   if (this.state.coucher === true) {
  //     this.setState({ hcoucherforpost: hour_ + ':' + minute_ + ':00' })
  //     this.setState({ heurecoucherpoursoustracction: hour_ }, () => {
  //     })
  //     this.setState({ minutecoucherpoursoustracction: minute_ }, () => {
  //     })
  //     let hcoucher = parseInt(hour_);
  //     let mncoucher = parseInt(minute_);
  //     let hreveil = parseInt(this.state.heurereveilpoursoustracction);
  //     let mnreveil = parseInt(this.state.minutereveilpoursoustracction);
  //     let hstart = 0;
  //     let mncoucherstart = 0;
  //     let hsommeil = 0;
  //     let mnsommeil = 0;
  //     if (hcoucher >= 0 && hcoucher <= 4 && hreveil >= 0 && hreveil <= 12) {
  //       hstart = hcoucher;
  //       mncoucherstart = mncoucher;
  //
  //       if (mncoucher > 0 && hcoucher <= hreveil) {
  //         mncoucherstart = mncoucher - 60;
  //         if (mncoucherstart < 0) {
  //           hstart = hcoucher + 1;
  //         }
  //       }
  //       // hsommeil =  Math.abs(hstart - hreveil);
  //       hsommeil = hreveil - hstart;
  //       if (hcoucher > this.state.heurereveilpoursoustracction) {
  //         mnsommeil = mncoucherstart - mnreveil
  //       } else {
  //         mnsommeil = Math.abs(mncoucherstart - mnreveil)
  //       }
  //
  //       // }else if(hcoucher>=5 && hcoucher<=18 && hreveil>= 0 && hreveil<=24  ) {
  //     } else if (hcoucher >= 0 && hcoucher <= 18 && hreveil >= 0 && hreveil <= 24) {
  //       hstart = hcoucher;
  //       mncoucherstart = mncoucher;
  //
  //       if (mncoucher > 0 && hcoucher <= hreveil) {
  //         mncoucherstart = mncoucher - 60;
  //         if (mncoucherstart < 0) {
  //           hstart = hcoucher + 1;
  //         }
  //       }
  //       hsommeil = hreveil - hstart;
  //       if (hcoucher > this.state.heurereveilpoursoustracction) {
  //         mnsommeil = mncoucherstart - mnreveil
  //       } else {
  //         mnsommeil = Math.abs(mncoucherstart - mnreveil)
  //       }
  //
  //     } else if (hcoucher >= 19 && hcoucher <= 24 && hreveil >= 19 && hreveil <= 24) {
  //       if (mncoucher > 0) {
  //         hstart = hcoucher + 1;
  //         mncoucherstart = mncoucher;
  //         hsommeil = hreveil - hstart;
  //         mnsommeil = mnreveil - mncoucherstart
  //       } else {
  //         hstart = hcoucher;
  //         mncoucherstart = 0;
  //         hsommeil = hreveil - hstart;
  //         mnsommeil = mnreveil - mncoucherstart
  //       }
  //     } else {
  //       if (mncoucher > 0) {
  //         hstart = (24 - 1) - hcoucher;
  //         mncoucherstart = 60 - mncoucher;
  //       } else {
  //         hstart = 24 - hcoucher;
  //         mncoucherstart = 0;
  //       }
  //       hsommeil = hreveil + hstart;
  //       mnsommeil = mnreveil + mncoucherstart
  //     }
  //
  //
  //     if (mnsommeil >= 60) {
  //       hsommeil = hsommeil + 1;
  //       mnsommeil = mnsommeil - 60
  //     }
  //
  //     let hour__reveil = hsommeil.toString().padStart(2, "0");
  //     let minute__reveil = mnsommeil.toString().padStart(2, "0");
  //     // let percentage = parseFloat(hsommeil + '.' + mnsommeil) * 100/parseFloat(this.state.hobjectif);
  //     let percentage = 0;
  //     if (this.state.hobjectif % 1 !== 0) {
  //       percentage = parseFloat(hsommeil + '.' + minute__reveil) * 100 / parseFloat(this.state.hobjectif.toString().split('.')[0] + '.' + this.state.hobjectif.toString().split('.')[1].toString().padEnd(2, '0'));
  //     } else {
  //       percentage = parseFloat(hsommeil + '.' + minute__reveil) * 100 / parseInt(this.state.hobjectif);
  //     }
  //     if (percentage > 100) {
  //       percentage = 100;
  //     }
  //     this.setState({ percentage: percentage });
  //     this.setState({ hsommeil: hour__reveil });
  //     this.setState({ mnsommeil: minute__reveil });
  //     // this.setState({hobjectif:parseFloat(hour__reveil + "." + minute__reveil)});
  //     this.setState({ coucher: false })
  //     this.hideDateTimePicker();
  //   } else if (this.state.reveil === true) { /////////////////////////////: ok result
  //     this.setState({ hreveilforpost: hour_ + ':' + minute_ + ':00' })
  //     let hreveil = parseInt(hour_);
  //     let mnreveil = parseInt(minute_);
  //     let hcoucher = parseInt(this.state.heurecoucherpoursoustracction);
  //     let mncoucher = parseInt(this.state.minutecoucherpoursoustracction);
  //     this.setState({ heurereveilpoursoustracction: hour_ })
  //     this.setState({ minutereveilpoursoustracction: minute_ })
  //
  //     let hstart = 0;
  //     let mncoucherstart = 0;
  //
  //     let hsommeil = 0;
  //     let mnsommeil = 0;
  //     if (hcoucher >= 0 && hcoucher <= 4 && hreveil >= 0 && hreveil <= 12) {
  //       hstart = hcoucher;
  //       mncoucherstart = mncoucher;
  //
  //
  //       if (mncoucher > 0 && hcoucher <= hreveil) {
  //         mncoucherstart = mncoucher - 60;
  //         if (mncoucherstart < 0) {
  //           hstart = hcoucher + 1;
  //         }
  //       }
  //       // hsommeil =  Math.abs(hstart - hreveil);
  //       hsommeil = hreveil - hstart;
  //       if (this.state.heurecoucherpoursoustracction > hreveil) {
  //         mnsommeil = mncoucherstart - mnreveil
  //       } else {
  //         mnsommeil = Math.abs(mncoucherstart - mnreveil)
  //       }
  //
  //     } else if (hcoucher >= 0 && hcoucher <= 18 && hreveil >= 0 && hreveil <= 24) {
  //       hstart = hcoucher;
  //       mncoucherstart = mncoucher;
  //
  //       if (mncoucher > 0 && hcoucher <= hreveil) {
  //         mncoucherstart = mncoucher - 60;
  //         if (mncoucherstart < 0) {
  //           hstart = hcoucher + 1;
  //         }
  //       }
  //       hsommeil = hreveil - hstart;
  //       if (this.state.heurecoucherpoursoustracction > hreveil) {
  //         mnsommeil = mncoucherstart - mnreveil
  //       } else {
  //         mnsommeil = Math.abs(mncoucherstart - mnreveil)
  //       }
  //
  //     } else if (hcoucher >= 19 && hcoucher <= 24 && hreveil >= 19 && hreveil <= 24) {
  //       if (mncoucher > 0) {
  //         hstart = hcoucher + 1;
  //         mncoucherstart = mncoucher;
  //         hsommeil = hreveil - hstart;
  //         mnsommeil = mnreveil - mncoucherstart
  //       } else {
  //         hstart = hcoucher;
  //         mncoucherstart = 0;
  //         hsommeil = hreveil - hstart;
  //         mnsommeil = mnreveil - mncoucherstart
  //       }
  //     } else {
  //       if (mncoucher > 0) {
  //         hstart = (24 - 1) - hcoucher;
  //         mncoucherstart = 60 - mncoucher;
  //       } else {
  //         hstart = 24 - hcoucher;
  //         mncoucherstart = 0;
  //       }
  //       hsommeil = hreveil + hstart;
  //       mnsommeil = mnreveil + mncoucherstart
  //     }
  //
  //
  //     if (mnsommeil >= 60) {
  //       hsommeil = hsommeil + 1;
  //       mnsommeil = mnsommeil - 60
  //     }
  //     let hour__reveil = hsommeil.toString().padStart(2, "0");
  //     let minute__reveil = mnsommeil.toString().padStart(2, "0");
  //     // let percentage = parseFloat(hsommeil + '.' + mnsommeil) * 100/parseFloat(this.state.hobjectif);
  //     let percentage = 0;
  //     if (this.state.hobjectif % 1 !== 0) {
  //       percentage = parseFloat(hsommeil + '.' + minute__reveil) * 100 / parseFloat(this.state.hobjectif.toString().split('.')[0] + '.' + this.state.hobjectif.toString().split('.')[1].toString().padEnd(2, '0'));
  //     } else {
  //       percentage = parseFloat(hsommeil + '.' + minute__reveil) * 100 / parseInt(this.state.hobjectif);
  //     }
  //     if (percentage > 100) {
  //       percentage = 100;
  //     }
  //     this.setState({ percentage: percentage });
  //     this.setState({ hsommeil: hour__reveil });
  //     this.setState({ mnsommeil: minute__reveil });
  //     this.setState({ reveil: false })
  //     this.hideDateTimePicker();
  //   } else if (this.state.monobjectif === true) {
  //     // let hobjectif_ = hour_+'.'+minute_;
  //     //
  //     // let monobjectif_post_ = null;
  //     //
  //     // let heuresommeils = Math.trunc(hobjectif_);
  //     // let mnsommeils = hobjectif_ % 1 !== 0 ? Number(String(hobjectif_).substr(String(hobjectif_).indexOf('.')+1)) : '00';
  //     // monobjectif_post_ = heuresommeils+'h'+mnsommeils;
  //     // this.setState({monobjectif_post_affichage:monobjectif_post_})
  //     // if(hobjectif_ ==0 || hobjectif_ === undefined || hobjectif_ === null){
  //     //     this.setState({hobjectif:24});
  //     // }else{
  //     //     console.warn('mba ato v tsika zan e')
  //     //     this.setState({hobjectif:parseFloat(hobjectif_)});
  //     // }
  //     this.setState({ monobjectif: false })
  //   }
  // };








}
