import { Component, OnInit, OnChanges} from '@angular/core';
import { Subject } from "rxjs";
import { DriverService } from 'src/app/services/driver.service';
import { RipeurService } from '../../services/ripeur.service';
import { CircuitService } from 'src/app/services/circuit.service';
import { BomService } from "../../services/bom.service";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit , OnChanges{

  public countDriver: any;
  public countRipeur: any;
  public countCircuit: any;
  public countBom: any;
  public listAnomalies: any = [];
  public listAnomalies_bac: any = [];
  public page : any;
  public title : any;
  public entrepriseName : any;
  public entrepriseType : any;

  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  constructor(
    private driverService: DriverService,
    private ripeurService: RipeurService,
    private circuitService: CircuitService,
    private bomService: BomService,
    private router: Router,
    private locationStrategy: LocationStrategy,
  ) {
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href)
    });
  }

  ngOnInit(): void {
    this.page = 'accueil';
    this.title = 'Tableau de bord';
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true,
      language: DatatableLanguage.datatableFrench
    };
    this.entrepriseName = localStorage.getItem('entrepriseName');
    this.entrepriseType = localStorage.getItem('entrepriseType');
    this.count();
  }

  ngOnChanges(): void {
    this.count();
  }

  public count(){
    if(this.entrepriseType == "1"){
      this.driverService.getDrivers()
      .subscribe((res: any) => {
        this.countDriver = res.data.length
      });

      this.ripeurService.getRipeurs()
      .subscribe((res: any) => {
        this.countRipeur = res.data.length
      });

      this.circuitService.getCircuits()
      .subscribe((res: any) => {
        this.countCircuit = res.data.length
      });

      this.bomService.getBoms()
      .subscribe((res: any) => {
        this.countBom = res.data.length
      })
    }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.driverService.getDriversByEntreprise(body)
        .subscribe((res: any) => {
          this.countDriver = res.data.length
        });

        this.ripeurService.getRipeursByEntreprise(body)
        .subscribe((res: any) => {
          this.countRipeur = res.data.length
        });

        this.circuitService.getCircuitsByEntreprise(body)
        .subscribe((res: any) => {
          this.countCircuit = res.data.length
        });

        this.bomService.getBomsByEntreprise(body)
        .subscribe((res: any) => {
          this.countBom = res.data.length
        })
    }
  }


  public toPage(page) {
    if(page == 'home'){
      this.page = 'accueil';
      this.title = 'Tableau de bord';
    }else if(page == 'chauffeur'){
      this.page = 'chauffeur';
      this.title = 'Chauffeurs';
    }else if(page == 'rapport'){
      this.page = 'rapport';
      this.title = 'Rapport affectation';
    }else if(page == 'ripeur'){
      this.page = 'ripeur';
      this.title = 'Ripeurs';
    }else if(page == 'bom'){
      this.page = 'bom';
      this.title = 'BOM';
    }else if(page == 'anomalie'){
      this.page = 'anomalie';
      this.title = 'Liste Anomalies';
    }else if(page == 'anomalie_bac'){
      this.page = 'anomalie_bac';
      this.title = 'Rapport Anomalies';
    }else if(page == 'vidage'){
      this.page = 'vidage';
      this.title = 'Vidage';
    }else if(page == 'circuit'){
      this.page = 'circuit';
      this.title = 'Circuits';
    }else if(page == 'collect'){
      this.page = 'collect';
      this.title = 'Rapport de collecte';
    }else if(page == 'affectation'){
      this.page = 'affectation';
      this.title = "Rapport d'affectation";
    }else if(page == 'tonnage'){
      this.page = 'tonnage';
      this.title = "Tonnage";
    }else if(page == 'entreprise'){
      this.page = 'entreprise';
      this.title = 'Entreprises';
    }else if(page == 'pointage'){
      this.page = 'pointage';
      this.title = 'Pointages';
    }else if(page == 'rapport_j'){
      this.page = 'rapport_j';
      this.title = 'Rapport journalier';
    }else if(page == 'rapportleveebac'){
      this.page = 'rapportleveebac';
      this.title = 'Rapport levée bac';
    }

  }

  public sign_out(){
    this.router.navigateByUrl('');
  }

}
