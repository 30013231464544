import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Collect} from "../constant/collect"
import { retry, catchError } from 'rxjs/operators'
import {Pointing} from "../constant/pointing";
import {Rapportjornalier} from "../constant/rapportjournalier";

@Injectable({
  providedIn: 'root'
})
export class CollectService {
  public apiUrl = environment.api;

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  /**
   * getCollects
   */
  public getCollects(limit): Observable<Collect> {
    return this.httpClient.get<Collect>(
      this.apiUrl + 'collects/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public excel(data): Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'collects/excel',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getCollectsByEntreprise
   */
  public getCollectsByEntreprise(data,limit): Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'collects/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteCollects
   */
  public deleteCollects(data): Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'collects/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getCollectBetweenTwoDates
   */
  public getCollectBetweenTwoDates(data): Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'collects/betweenTwoDates',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getCollectBetweenTwoDatesByEntreprise(data): Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'collects/betweenTwoDatesByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public supprimerPlusieursCollects(data):Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'collects/supprimerPlusieurs',
      JSON.stringify({arrayCollecte:data}),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
