import {Component, OnInit, EventEmitter, ViewChild, OnChanges, Inject, LOCALE_ID} from '@angular/core';
import { RapportleveebacService } from '../../services/rapportleveebac.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {formatDate} from "@angular/common";
import * as moment from 'moment';
import {PointingService} from "../../services/pointing.service";
import {PdfService} from "../../services/pdf.service";


@Component({
  selector: 'app-rapportleveebac',
  templateUrl: './rapportleveebac.component.html',
  styleUrls: ['./rapportleveebac.component.css']
})

export class RapportleveebacComponent implements OnInit, OnChanges {
  @ViewChild('confirmCheckDeletePlsRapportBac') confirmCheckDeletePlsRapportBac: any;
  @ViewChild('confirmDelete') confirmDelete: any;
    @ViewChild('openCart') openCart: any;
  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listBom: any = [];
  public listRapportBac: any = [];
  public bomforfiltre: string = "";
  public debutbybom: any;
  public finbybom: any;
  public debut: any;
  public Hdebut: any;
  public fin: any;
  public Hfin: any;
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public entrepriseType : any;
  public RapportBacId: any;
  public aujourdui: any;
  public hier: any;
  public debut_semaine: any;
  public fin_semaine: any;
  public debut_mois: any;
  public fin_mois: any;
  public emitData: EventEmitter<any> = new EventEmitter;
  public a: boolean;
  private limit: number = 100;
  private nombreDeFois: number = 1;
  public checkActiondemande: any;
  public checkEnableDisablePlusieursRapportBacArray: any = [];
  public utc: any;

  constructor(
    private rapportleveebacService: RapportleveebacService,
    private pdfService: PdfService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private pointingService: PointingService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.a = false;
    this.utc = {typeSigne: 0, offset: 0};
  }

  public date_aujour_d_hui(){
    var ladate = new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1)+'-'+ladate.getDate()
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    //Get utc
    const utc = localStorage.getItem("utc");
    if (utc !== null) {
      this.utc = JSON.parse(utc);
    }
    this.aujourdui = this.pointingService.date_aujour_d_hui();
    this.hier = this.pointingService.less_day_date(this.date_aujour_d_hui(),1);
    this.traitement_semaine();
    this.traitement_mois();
    this.a = false;
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 0, 'desc' ]],
      "autoWidth": true,
      "responsive": true,
    };
    this.entrepriseType = localStorage.getItem('entrepriseType');
    this.iff()
  }



  public traitement_semaine(){
    var ladate=new Date();
    var position = ladate.getDay();
    this.debut_semaine =  this.pointingService.less_day_date(this.date_aujour_d_hui(), position);
    this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine,6);
  }


    public traitement_mois(){
    var mois = this.aujourdui.slice(5,7);
    var annees = this.aujourdui.slice(0,4);
    var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    this.debut_mois = this.aujourdui.slice(0,8)+'01';
    this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  }

  ngOnChanges(): void {
    this.loadRapportBac()
  }

  /**
   * loadRapportBac
   */
  public loadRapportBac(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.iff()
    })
  }
  iff(){
    if(this.entrepriseType == "1"){
      this.rapportleveebacService.getAllBoms()
        .subscribe((res: any) => {
          this.listBom = res.data;
        });
      this.rapportleveebacService.getRapportBac(this.limit)
        .subscribe((res: any) => {
          this.listRapportBac = res.data;
          this.bomforfiltre = res.data[res.data.length - 1].bom;
          console.log(this.bomforfiltre);
          this.spinner.hide();
          this.dtTigger.next()
        })
    }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.rapportleveebacService.getBomsByEntreprise(body)
        .subscribe((res: any) => {
          this.listBom = res.data;
        });
      this.rapportleveebacService.getRapportBacByEntreprise(body,this.limit)
        .subscribe((res: any) => {
          this.listRapportBac = res.data;
          this.bomforfiltre = res.data[res.data.length - 1].bom;
          console.log(this.bomforfiltre)
          this.spinner.hide();
          if(body.entrepriseId === "2"){
            this.Hdebut = '00:00';
            this.Hfin = '23:59';
            this.debut = this.pointingService.date_aujour_d_hui();
            this.fin = this.pointingService.date_aujour_d_hui();
          }else if(body.entrepriseId === "3"){
            this.Hdebut = '17:00';
            this.Hfin = '16:00';
            this.debut= moment.utc(this.pointingService.date_lendemain()).format("yyyy-MM-DD");
            this.fin = this.pointingService.date_aujour_d_hui();
          }
          //necessaire pour per bom per month
          let start_ = this.debut;
          let end_ = this.fin;
          if(this.Hdebut != undefined || this.Hfin != undefined){
            start_ = this.debut + " " + this.Hdebut + ":00";
            end_ = this.fin + " " + this.Hfin + ":00";
          }
          //
          this.debutbybom = start_;
          this.finbybom = end_;
          console.log('ng on init', this.debut, this.debutbybom)
          this.dtTigger.next()
        })
    }
  }
  public aa() {
    this.a = true
   }

    bb() {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.a = false;
      this.rapportleveebacService.getRapportBac(this.limit)
      .subscribe((res: any) => {
        this.listRapportBac = res.data;
        this.spinner.hide();
      })
    }


   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.RapportBacId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * deleteRapportBac
   */
  public deleteRapportBac() {
    let body = {
      id: this.RapportBacId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.rapportleveebacService.deleteRapportBac(body)
      .subscribe((res: any) => {
        this.loadRapportBac();
        this.resetField();
        this.spinner.hide();
      })
  }


  public supprimerPlusieursRapportBac() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.rapportleveebacService.supprimerPlusieursRapportBac(this.checkEnableDisablePlusieursRapportBacArray)
      .subscribe((res: any) => {
        this.loadRapportBac();
        this.resetField();
        this.spinner.hide()
      })
  }

  public openModaPourSupprimerPlusieursRapportBac() {
    this.checkActiondemande = 'add';
    this.ngbModal.open(this.confirmCheckDeletePlsRapportBac)
  }

  public checkEnableDisablePlusieursRapportBac(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursRapportBacArray.length; i++){
        if(this.checkEnableDisablePlusieursRapportBacArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursRapportBacArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursRapportBacArray = this.checkEnableDisablePlusieursRapportBacArray.filter(function (item) {
        return item !== id;
      });
    }
  }


  public afficherBy2Date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.debutbybom = start_;
    this.finbybom = end_;
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_,
          immatriculation: this.bomforfiltre
        };
        this.rapportleveebacService.getRapportBacBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listRapportBac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_,
          immatriculation: this.bomforfiltre
        };
        this.rapportleveebacService.getRapportBacBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listRapportBac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })

      }
    })
  }


  public getBy2date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.debutbybom = start_;
    this.finbybom = end_;
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_,
          immatriculation: this.bomforfiltre
        };
        this.rapportleveebacService.getRapportBacBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listRapportBac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
        console.log('one start 1', start_);
        console.log('one end 1',  end_);
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_,
          immatriculation: this.bomforfiltre
        };
        console.log("date 3 body e", bodyE.startDate, bodyE.endDate);
        if(bodyE.entrepriseId=="3"){
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: moment(start).format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment(end).format("YYYY-MM-DD HH:mm:ss"),
          immatriculation:this.bomforfiltre
        };
         console.log("date 2", bodyE.startDate, bodyE.endDate);
        this.rapportleveebacService.getRapportBacBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listRapportBac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
        }else{
        this.rapportleveebacService.getRapportBacBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listRapportBac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
        };

      };
    })
  }




  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
    // this.id = '';
  }



   timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }






  generateExcel(){
    let id_E = "";
    if(this.entrepriseType == "1"){
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let listRapportBacExcel = [];
    if(this.listRapportBac.length > 0) {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      for (let i = 0; i < this.listRapportBac.length; i++) {
        const item = this.listRapportBac[i];
        let transform0 = null;
        if (item.createdAt !== null && item.createdAt !== undefined) {
          transform0 = (item.createdAt).toString().substring(0, 10);
          let tttt;
          if (this.utc.typeSigne == 0) {
            tttt = moment(item.createdAt).subtract(this.utc.offset, 'h');
          } else {
            tttt = moment(item.createdAt).add(this.utc.offset, 'h');
          }
          item.createdAt = tttt;
        }
        listRapportBacExcel.push(item);
      }
      let body = {
        listRapportBacExcel: listRapportBacExcel,
        id_E: id_E
      };
      //console.log('body',JSON.stringify(listRapportBacExcel));
      this.rapportleveebacService.excel(body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = res.url;
            document.body.appendChild(a);
            a.click();
            this.loadRapportBac();
            this.resetField();
            this.spinner.hide();
            document.body.removeChild(a);
          }
        })
    }
  }

  public openMapbis(gps) {
    if(gps){

      this.ngbModal.open(this.openCart);
      const latitude = gps.split(',')[0];
      const long = gps.split(',')[1];
      const mapProperties = {
        center: new google.maps.LatLng(latitude, long),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      //this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.map = new google.maps.Map(document.getElementById("map"), mapProperties);
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, long),
        map: this.map,
        title: 'markers'
      });
      google.maps.event.trigger(this.map, "resize")
    }
  }

  generatePdf(type) {
    this.pdfService.generatePdfRapportBac(this.listRapportBac,this.debut,this.fin,type, this.locale);
  }


  choisirbomfilter(immatriculation) {
    this.bomforfiltre = immatriculation;
    let id_E = "";
    if(this.entrepriseType == "1"){
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);


    let start_ = this.debutbybom;
    let end_ = this.finbybom;
      start_ = this.debut + " " + this.Hdebut + ":00";
      end_ = this.fin + " " + this.Hfin + ":00";
    console.log('startend voaray am rapport bom', start_, 'end', end_, 'this.debut',this.debut, 'this.fin',this.fin)
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_,
          immatriculation: immatriculation,
        };
        this.rapportleveebacService.getRapportBacBetweenTwoDatesbybom(body, this.limit)
          .subscribe((res: any) => {
            if (res.status == 200) {
              console.log('la resultat', res.data)
              this.listRapportBac = res.data;
              this.spinner.hide();
              this.dtTigger.next();
        }});
        console.log('one start 1', start_);
        console.log('one end 1',  end_);
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_,
          immatriculation: immatriculation,
        };
        console.log("date 3 body e", bodyE.startDate, bodyE.endDate);
        if(bodyE.entrepriseId=="3"){
          let bodyE = {
            entrepriseId: localStorage.getItem('entrepriseId'),
            // startDate: moment(start).format("YYYY-MM-DD HH:mm:ss"),
            startDate: moment(start_).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(end_).format("YYYY-MM-DD HH:mm:ss"),
            immatriculation: immatriculation
          };
          console.log("date 2", bodyE.startDate, bodyE.endDate);
          this.rapportleveebacService.getRapportBacBetweenTwoDatesByEntreprisebybom(bodyE,this.limit)
            .subscribe((res: any) => {
              console.log('la resultat 1', res.data);
              this.listRapportBac = res.data;
              this.spinner.hide();
              this.dtTigger.next()
            });
        }else{
          this.rapportleveebacService.getRapportBacBetweenTwoDatesByEntreprisebybom(bodyE,this.limit)
            .subscribe((res: any) => {
              this.listRapportBac = res.data;
              this.spinner.hide();
              this.dtTigger.next()
            });
        };
      };
    })




  }

}
