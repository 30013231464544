import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Anomalies_bac } from "../constant/anomalies_bac"
import { retry, catchError } from 'rxjs/operators'
import {Collect} from "../constant/collect";
import {Pointing} from "../constant/pointing";

@Injectable({
  providedIn: 'root'
})
export class Anomalies_bacService {

  public apiUrl = environment.api;
  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  /**
   * getAnomalies
   */
  public getAnomalies_bac(limit): Observable<Anomalies_bac> {
    return this.httpClient.get<Anomalies_bac>(
      this.apiUrl + 'anomalies-bac/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public excel(data): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'anomalies-bac/excel',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getAnomalies_bacByEntreprise
   */
  public getAnomalies_bacByEntreprise(data,limit): Observable<Anomalies_bac> {
    return this.httpClient.post<Anomalies_bac>(
      this.apiUrl + 'anomalies-bac/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * Anomalies_bac
   */
  public deleteAnomalies_bac(data): Observable<Anomalies_bac> {
    return this.httpClient.post<Anomalies_bac>(
      this.apiUrl + 'anomalies-bac/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public supprimerPlusieursAnomaliesbacs(data):Observable<Anomalies_bac> {
    return this.httpClient.post<Anomalies_bac>(
      this.apiUrl + 'Anomaliesbacs/supprimerPlusieurs',
      JSON.stringify({arrayAnomaliesbacs:data}),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }



  public getAnnomalyBetweenTwoDates(data): Observable<Anomalies_bac>{
    return this.httpClient.post<Anomalies_bac>(
      this.apiUrl + 'anomalies-bac/betweenTwoDates',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getAnnomalyBetweenTwoDatesByEntreprise(data): Observable<Anomalies_bac> {
    return this.httpClient.post<Anomalies_bac>(
      this.apiUrl + 'anomalies-bac/betweenTwoDatesByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
