import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import {TimezoneService} from "../../services/timezone.service";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( private timezoneService: TimezoneService ) { }

  ngOnInit(): void {
    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position: Position) => {
            if (position) {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              const location = lat+','+lng;
              console.log('location',location);
              await this.timezoneService.getTimeZone(location).subscribe(res=>{
                if(res){
                 localStorage.setItem("utc", JSON.stringify(res));
                 console.log("utc",res);
                }
              })
            }
          },
          (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

}
