    <script src="pointage.component.ts"></script>
    <link rel="stylesheet" href="pointage.component.css">
    <div>
      <div style="" class="btn-periode" role="group" aria-label="Basic example">
        <button style="margin-left: 15px; margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(hier,hier)">Hier</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="generatePdf('print')">Imprimer</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut,fin)">Afficher</button>
          <button style="margin-right: 5px;" class="btn btn-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" >
            Télécharger
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="generatePdf('download')">Fichier PDF</a>
            <a class="dropdown-item" (click)="generateExcel()">Fichier Excel</a>
          </div>

        <input style="margin-right: 5px;" id="date" type="date" value="" name="debut" [(ngModel)]="debut">
        <input style="margin-right: 5px;" id="heure" type="time" value="" name="H debut" [(ngModel)]="Hdebut">
        <input style="margin-right: 5px;" id="date1" type="date" value="" name="fin" [(ngModel)]="fin">
        <input style="margin-right: 5px;" id="heure1" type="time" value="" name="H fin" [(ngModel)]="Hfin">


      </div>
        <div class="card-body">
<!--          <div class="row">-->
<!--&lt;!&ndash;            dddddddddddd&ndash;&gt;-->
<!--            <div class="row periode" style="display: flex; flex-direction: row; margin-top: 20px;margin-left: 20px;">-->
<!--              <div class="col-sm-1">Du:</div>-->
<!--              <div class="col-sm-3"><input id="date" type="date" value="" name="debut" [(ngModel)]="debut"></div>-->
<!--              <div class="col-sm-1">A:</div>-->
<!--              <div class="col-sm-3"><input id="date1" type="date" value="" name="fin" [(ngModel)]="fin"></div>-->

<!--            </div>-->
<!--          </div>-->
            <div class="row">
<!--                <div>-->
<!--                <button class="btn btn-info btn-sm fichier" color="primary" (click)="generatePdf('affiche')">-->
<!--                    Afficher PDF-->
<!--                </button>-->
<!--&lt;!&ndash;                  <button class="btn btn-info btn-sm fichier" color="primary" (click)="generatePdf('download')">&ndash;&gt;-->
<!--&lt;!&ndash;                    Telecharger PDF&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--                  <button class="btn btn-info btn-sm fichier" color="primary" (click)="generateExcel()">-->
<!--                  Telecharger Excel-->
<!--                </button>-->
<!--                  <br><br>-->
<!--              </div>-->
                <!-- <table class="table"> -->
                    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
                    <thead>
                        <tr>
                          <th></th>
                          <th *ngIf="entrepriseType === '1'">Entreprise</th>
                          <th>Date</th>
                          <th>Véhicule</th>
                            <th>Circuit</th>
                            <th>Qualité </th>
                            <th>Nom</th>
                            <th>Prénoms</th>
                            <th>H de début</th>
                            <th>H de Fin</th>
                            <th>Temps de travail</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listPointing">
                          <td >
                            <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieursPointages(item?.id, $event)" >
                          </td>
                          <td *ngIf="entrepriseType === '1'">{{item.entrepriseId != null ? item.entreprise.name:''}}</td>
                          <td>{{item.createdAt | date:'yyyy-MM-dd'}}</td>
                          <td>{{item.bomId != null ? item.bom.immatriculation: ''}}</td>
                            <!--<td>{{item.date | date:'dd/MM/yyyy'}}</td>-->
                            <td>{{item.circuitId != null ? item.circuit.name:''}}</td>
                            <td>{{item.quality}}</td>
                            <td>{{item.firstname}}</td>
                          <td>{{item.lastname}}</td>
                          <td>{{item.createdAtBegin != null ? (item.createdAtBegin | date:'HH:mm:ss') : ''}}</td>
                            <td>{{item.createdAtEnd  != null ? (item.createdAtEnd | date:'HH:mm:ss') : ''}}</td>
                                <td>{{item.end != null ? dateDiffbycreatedAt(item.createdAtBegin, item.createdAtEnd):'en cours'}}</td>
<!--                              navela koa  <td>{{item.end != null ? dateDiff(debut+' '+item.start, debut+' '+item.end):'en cours'}}</td>-->
<!--                          <td>{{item.end != null ? dateDiff1(debut+' '+item.start, debut+' '+item.end, item.start, item.end):'en cours'}}</td>-->
                          <!--       // (item.start | date:'HH'),
                          // (item.start | date:'mm'),
                          // (item.start | date:'ss'),
                          //
                          // (item.end | date:'HH'),
                          // (item.end | date:'mm'),
                          // (item.end | date:'ss')



                                       <td>{{item.createdAt | date:'HH:mm:ss'}}</td>-->
<!--                        <td>{{item.updatedAt == item.createdAt ? '' : item.updatedAt | date:'HH:mm:ss' }}</td>-->
<!--                        <td>{{item.updatedAt == item.createdAt ? 'en cours' : dateDiff(debut+' '+item.createdAt , debut+' '+item.updatedAt)}}</td>-->
                          <td>
                            <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
                                <i class="fas fa-trash"></i>
                            </button>
                           </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieursPointages()">
            Supprimer les pointages cochés
          </button>
        </div>
    </div>
    <ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deletePointing()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
    </ng-template>

    <ng-template #confirmCheckDeletePlsPointages>
      <div class="card-body">
        Êtes-vous sûr de vouloir supprimer ces pointages?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieursPointages()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
      </div>
    </ng-template>
