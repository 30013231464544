
<script src="anomaly-bac.component.ts"></script>
<div>
  <div style="margin-top: 15px" class="btn-periode" role="group" aria-label="Basic example">
    <button style="margin-left: 15px; margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>
    <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(hier,hier)">Hier</button>
    <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>
    <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>
    <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="generatePdf('print')">Imprimer</button>
    <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut,fin)">Afficher</button>
    <button style="margin-right: 5px;" class="btn btn-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" >
      Télécharger
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" (click)="generatePdf('download')">Fichier PDF</a>
      <a class="dropdown-item" (click)="generateExcel()">Fichier Excel</a>
    </div>

    <input style="margin-right: 5px;" id="date" type="date" value="" name="debut" [(ngModel)]="debut">
    <input style="margin-right: 5px;" id="heure" type="time" value="" name="H debut" [(ngModel)]="Hdebut">
    <input style="margin-right: 5px;" id="date1" type="date" value="" name="fin" [(ngModel)]="fin">
    <input style="margin-right: 5px;" id="heure1" type="time" value="" name="H fin" [(ngModel)]="Hfin">
  </div>
  <div class="card-body">
    <!--  <div class="row">-->
    <!--    <div style="margin-left: 7px;" class="btn-group btn-periode" role="group" aria-label="Basic example">-->
    <!--      <button type="button" class="btn btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>-->
    <!--      <button type="button" class="btn btn-info" (click)="getBy2date(hier,hier)">Hier</button>-->
    <!--      <button type="button" class="btn btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>-->
    <!--      <button type="button" class="btn btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>-->
    <!--    </div>-->

    <!--    <div class="row periode" style="display: flex; flex-direction: row; margin-top: 20px;margin-left: 20px;">-->
    <!--      <div class="col-sm-1">Du:</div>-->
    <!--      <div class="col-sm-3"><input id="date" type="date" value="" name="debut" [(ngModel)]="debut"></div>-->
    <!--      <div class="col-sm-1">A:</div>-->
    <!--      <div class="col-sm-3"><input id="date1" type="date" value="" name="fin" [(ngModel)]="fin"></div>-->
    <!--      <div class="col-sm-2">-->
    <!--        <button class="btn btn-info btn-sm pull-right" (click)="getBy2date(debut,fin)">-->
    <!--          <i class="fa fa-list" aria-hidden="true"></i> Afficher-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <div class="row">
      <!--       <div style="margin-top: 20px">-->
      <!--          <button class="btn btn-info btn-sm fichier" color="primary" (click)="generatePdf('affiche')">-->
      <!--              Afficher PDF-->
      <!--          </button><button class="btn btn-info btn-sm fichier" color="primary" (click)="generatePdf('download')">-->
      <!--              Telecharger PDF-->
      <!--          </button><button class="btn btn-info btn-sm fichier" color="primary" (click)="generatePdf('print')">-->
      <!--              Imprimer-->
      <!--          </button> <button class="btn btn-info btn-sm fichier" color="primary" (click)="generateExcel()">-->
      <!--         Telecharger Excel-->
      <!--       </button><br><br>-->
      <!--      </div>-->

      <!-- <table class="table"> -->
      <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
        <thead>
        <tr>
          <th></th>
          <th *ngIf="entrepriseType == '1'">Entreprise</th>
          <!-- <th>Photo</th> -->
          <th>Date</th>
          <th>Anomalie</th>
          <th>Chauffeur</th>
          <th>Circuit</th>
          <th>Id Bac</th>
          <th>Localisation</th>
          <th>Commentaire</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of listAnomalies_bac">
          <td >
            <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieursAnomaliesbacs(item?.id, $event)" >
          </td>
          <td *ngIf="entrepriseType == '1'">{{item.entrepriseId != null ? item.entreprise.name:''}}</td>
          <!-- <td><img [src]="item.image_" style="width:100px;height:100px;"/></td> -->
          <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
          <td>{{item.anomalyId != null ? item.anomaly.description:''}}</td>
          <td>{{item.driverId != null ? item.driver.lastname+' '+item.driver.firstname:''}}</td>
          <td>{{item.circuitId != null ? item.circuit.name:''}}</td>
          <td>{{item.idBac != null ? item.idBac :''}}</td>
          <td><a href="javascript:void(0)"  (click)="openMap(item.latitude,item.longitude)">{{item.latitude != null && item.longitude != null ? item.latitude+', '+item.longitude:''}}</a></td>
          <td>{{item.comment != null ? item.comment :''}}</td>
          <td>
            <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
    <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieursAnomaliesbacs()">
      Supprimer les rapports cochés
    </button>
  </div>
</div>

<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deleteAnomalies_bac()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #openCart>
  <div class="card-body">
    <div id="map" style="width:100%;height:400px"></div>
  </div>
</ng-template>


<ng-template #confirmCheckDeletePlsAnomaliesbacs>
  <div class="card-body">
    Êtes-vous sûr de vouloir supprimer ces rapports?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieursAnomaliesbacs()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>
