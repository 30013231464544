import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Report} from "../constant/report"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  public apiUrl = environment.api

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }

  /**
   * getReports
   */
  public getReports(): Observable<Report> {
    return this.httpClient.get<Report>(
      this.apiUrl + 'reports/getAll',
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getReportsByEntreprise
   */
  public getReportsByEntreprise(data): Observable<Report> {
    return this.httpClient.post<Report>(
      this.apiUrl + 'reports/getAllByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * Anomalies_bac
   */
  public deleteReports(data): Observable<Report> {
    return this.httpClient.post<Report>(
      this.apiUrl + 'reports/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
