import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { BomService } from "../../services/bom.service";
import { EntrepriseService } from "../../services/entreprise.service";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-bom',
  templateUrl: './bom.component.html',
  styleUrls: ['./bom.component.css']
})
export class BomComponent implements OnInit, OnChanges {
  @ViewChild('actionBom') actionBom: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listBom: any = [];
  public listEntreprise: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public bomId: any;
  public bomImmatriculation: any;
  public bomName: any;
  public bomBand: any;
  public bomComment: any;
  public idEntreprise: any;
  public entrepriseType : any;
  // check action
  public checkAction: any;

  constructor(
    private bomService: BomService,
    private entrepriseService: EntrepriseService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseType = localStorage.getItem('entrepriseType');
      if(this.entrepriseType == "1"){
        this.bomService.getBoms()
        .subscribe((res: any) => {
          this.listBom = res.data;
          this.spinner.hide();
          console.log('listRipeur', this.listBom);
          this.dtTigger.next()
        })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.bomService.getBomsByEntreprise(body)
          .subscribe((res: any) => {
            this.listBom = res.data;
            this.spinner.hide();
            console.log('listBom', this.listBom);
            this.dtTigger.next()
          })
      }

      this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data
      })
  }

  ngOnChanges(): void {
    this.loadBom()
  }


  /**
   * loadBom
   */
  public loadBom(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.bomService.getBoms()
        .subscribe((res: any) => {
          this.listBom = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        });

        this.entrepriseService.getEntreprises()
          .subscribe((res: any) => {
            this.listEntreprise = res.data
          })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.bomService.getBomsByEntreprise(body)
          .subscribe((res: any) => {
            this.listBom = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }

  /**
   * emitDataBoms
   */
  public async emitDataBoms(item: any) {
    this.checkAction = 'update';
    this.emitData.emit(item);
    this.bomId = item.id;
    this.bomImmatriculation = item.immatriculation;
    this.bomName = item.name;
    this.bomBand = item.band;
    this.bomComment = item.comment;
    this.idEntreprise = item.entrepriseId;
    await <any>this.ngbModal.open(this.actionBom)
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.bomId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  /**
   * addBom
   */
  public addBoms() {
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      immatriculation: this.bomImmatriculation,
      name: this.bomName,
      band: this.bomBand,
      comment: this.bomComment,
      entrepriseId: id_E,
    };

    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.bomService.addBoms(body)
      .subscribe((res: any) => {
        this.loadBom();
        this.resetField();
        this.spinner.hide();
      })
  }

   /**
   * updateBoms
   */
  public updateBoms(): void{
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      id: this.bomId,
      immatriculation: this.bomImmatriculation,
      name: this.bomName,
      band: this.bomBand,
      comment: this.bomComment,
      entrepriseId: id_E,
    };

     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.bomService.updateBoms(body)
      .subscribe((res: any) => {
        this.loadBom();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * deleteBom
   */
  public deleteBoms() {
    let body = {
      id: this.bomId
    }

    this.bomService.deleteBoms(body)
      .subscribe((res: any) => {
        this.loadBom();
        this.resetField()
      })
  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.checkAction = 'add';
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll();
    this.bomImmatriculation = '';
    this.bomName = '';
    this.bomBand = '';
    this.bomComment = '';
    this.idEntreprise = ''
  }
}
