import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Email } from "../constant/email"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  public apiUrl = environment.api;
  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  /**
   * getCollects
   */
  public getEmail(limit): Observable<Email> {
    return this.httpClient.get<Email>(
      this.apiUrl + 'email/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getCollectsByEntreprise
   */
  public getEmailByEntreprise(data,limit): Observable<Email> {
    return this.httpClient.post<Email>(
      this.apiUrl + 'email/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteCollects
   */
  public deleteEmail(data): Observable<Email> {
    return this.httpClient.post<Email>(
      this.apiUrl + 'email/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * addEmail
   */
  public addEmail(data): Observable<Email> {
    return this.httpClient.post<Email>(
      this.apiUrl + 'email/add',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * updateEmail
   */
  public updateEmail(data): Observable<Email> {
    return this.httpClient.post<Email>(
      this.apiUrl + 'email/update',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * envoyerEmailManually
   */
  public envoyerEmailManually(data): Observable<Email> {
    return this.httpClient.post<Email>(
      this.apiUrl + 'email/envoyerEmailManually',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }



  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
