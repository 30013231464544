import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DriverService } from 'src/app/services/driver.service';
import { EntrepriseService } from "../../services/entreprise.service";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit, OnChanges {

  @ViewChild('actionDriver') actionDriver: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listDriver: any = []

  public listEntreprise: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;

  // variable base
  public driverId: any;
  public driverImmatricule: any;
  public driverFirstname: any;
  public driverLastname: any;
  public driverAddress: any;
  public driverPhone: any;
  public idEntreprise: any;
  public entrepriseType : any;
  // check action
  public checkAction: any;

  constructor(
    private driverService: DriverService,
    private entrepriseService: EntrepriseService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseType = localStorage.getItem('entrepriseType');
        if(this.entrepriseType == "1"){
          this.driverService.getDrivers()
        .subscribe((res: any) => {
          this.listDriver = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        }
        this.driverService.getDriversByEntreprise(body)
          .subscribe((res: any) => {
            this.listDriver = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }

      this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data
      })
  }

  ngOnChanges(): void {
    this.loadDrivers()
  }

  /**
   * loadDrivers
   */
  public loadDrivers(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
          this.driverService.getDrivers()
        .subscribe((res: any) => {
          this.listDriver = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.driverService.getDriversByEntreprise(body)
          .subscribe((res: any) => {
            this.listDriver = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
        }
    })
  }

  /**
   * emitDataDrivers
   */
  public async emitDataDrivers(item: any) {
    this.checkAction = 'update';
    this.emitData.emit(item);
    this.driverId = item.id;
    this.driverFirstname = item.firstname;
    this.driverLastname = item.lastname;
    this.driverImmatricule = item.immatricule;
    this.driverAddress = item.address;
    this.driverPhone = item.phone;
    this.idEntreprise = item.entrepriseId;
    await <any>this.ngbModal.open(this.actionDriver)
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    console.log('emit id to delete', itemId);
    this.driverId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  /**
   * addDrivers
   */
  public addDrivers() {
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      firstname: this.driverFirstname,
      lastname: this.driverLastname,
      immatricule: this.driverImmatricule,
      address: this.driverAddress,
      phone: this.driverAddress,
      entrepriseId: id_E,
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.driverService.addDrivers(body)
      .subscribe((res: any) => {
        this.loadDrivers();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * updateDrivers
   */
  public updateDrivers(): void{
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      id: this.driverId,
      firstname: this.driverFirstname,
      lastname: this.driverLastname,
      immatricule: this.driverImmatricule,
      address: this.driverAddress,
      phone: this.driverPhone,
      entrepriseId: id_E,
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.driverService.updateDrivers(body)
      .subscribe((res: any) => {
        console.log(res);
        this.loadDrivers();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * deleteDrivers
   */
  public deleteDrivers() {
    let body = {
      id: this.driverId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.driverService.deleteDrivers(body)
      .subscribe((res: any) => {
        this.loadDrivers();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.checkAction = 'add';
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll();
    this.driverImmatricule = '';
    this.driverFirstname = '';
    this.driverLastname = '';
    this.driverPhone = '';
    this.driverAddress = '';
    this.idEntreprise = '';
  }
}
