
        <div class="card-head">
            <button class="btn btn-info btn-sm pull-right" (click)="openModal(actionEntreprise)">
               <i class="fa fa-plus" aria-hidden="true"></i> Nouveau
            </button>
        </div>

        <div class="card-body">
            <div class="row">
                <!-- <table class="table"> -->
                    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>E-mail</th>
                            <th>Mot de passe</th>
                            <th>Statut</th>
                            <th>Adresse</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listEntreprise" class="pointer-cl">
                            <td (click)="emitDataEntreprise(item)">{{item.name}}</td>
                            <td (click)="emitDataEntreprise(item)">{{item.email}}</td>
                            <td (click)="emitDataEntreprise(item)">{{decryptUsingAES256(item.password)}}</td>
                            <td (click)="emitDataEntreprise(item)">{{item.type == '1' ? 'Admin': 'Entreprise'}}</td>
                            <td (click)="emitDataEntreprise(item)">{{item.adress}}</td>
                            <td>
                                <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


<ng-template #actionEntreprise>
    <div class="modal-header">

        <h4 class="modal-title" *ngIf="checkAction === 'add'">Nouveau Entreprise</h4>
        <h4 class="modal-title" *ngIf="checkAction === 'update'">Modification Entreprise</h4>

        <button type="button" class="close" aria-label="Close" (click)="resetField()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Nom</label>
            <input type="text" class="form-control" name="entrepriseName" [(ngModel)]="entrepriseName">
        </div>

      <div class="form-group">
        <label>E-mail</label>
        <input type="text" class="form-control" name="entrepriseName" [(ngModel)]="entrepriseEmail">
      </div>

        <div class="form-group">
            <label>Mot de passe</label>
            <input type="text" class="form-control" name="entreprisePassword" [(ngModel)]="entreprisePassword">
        </div>

        <div class="form-group">
            <label>Statut</label>
            <select [(ngModel)]="entrepriseType" [ngModelOptions]="{standalone: true}" class="form-control">
                <option  value="1">Admin</option>
                <option  value="0">Entreprise</option>
            </select>
        </div>

        <div class="form-group">
            <label>Adresse</label>
            <input type="text" class="form-control" name="entrepriseAdress" [(ngModel)]="entrepriseAdress">
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="checkAction === 'add'" type="button" class="btn btn-info" (click)="addEntreprise()"><i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter</button>
        <button *ngIf="checkAction === 'update'" type="button" class="btn btn-info" (click)="updateEntreprise()"><i class="fa fa-check-circle" aria-hidden="true"></i> Enregister</button>
    </div>
</ng-template>

<ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteEntreprise()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
</ng-template>

