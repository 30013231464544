<div class="card-body">
    <div class="row">
        <!-- <table class="table"> -->
            <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
            <thead>
                <tr>
                    <th *ngIf="entrepriseType == '1'">Entreprise</th>
                    <th>Circuit</th>
                    <th>BOM</th>
                    <th>Chauffeur</th>
                    <th>Ripeur 1</th>
                    <th>Ripeur 2</th>
                    <th>Date</th>
                    <th>Debut</th>
                    <th>Fin</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listReport">
                    <td *ngIf="entrepriseType == '1'">{{item.entrepriseId != null ? item.entreprise.name:''}}</td>
                    <td>{{item.circuitId != null ? item.circuit.name:''}}</td>
                    <td>{{item.bomId != null ? item.bom.name: ''}}</td>
                    <td>{{item.driverId != null ? item.driver.lastname+' '+item.driver.firstname:''}}</td>
                    <td>{{item.lastnameripeur1+' '+item.firstnameripeur1}}</td>
                    <td>{{item.lastnameripeur2+' '+item.firstnameripeur2}}</td>
                    <td>{{item.date | date:'dd/MM/yyyy'}}</td>
                    <td>{{item.hourBegin}}</td>
                    <td>{{item.hourEnd}}</td>
                    <td>
                        <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteReports()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
</ng-template>
