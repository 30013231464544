import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import {RapportleveebacService} from "../../services/rapportleveebac.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-downloadattach',
  templateUrl: './downloadattach.component.html',
  styleUrls: ['./downloadattach.component.css']
})
export class DownloadattachComponent implements OnInit {

  constructor( private rapportleveebacService: RapportleveebacService, private route: ActivatedRoute) { }
  public results = {};
  ngOnInit(): void {
   // const bobo = this.route.snapshot
    this.getAttach();
  }

  getAttach() {
    console.log('manomboka n down attach');
    this.rapportleveebacService.downloadattachment1().subscribe((res: any) => {
      console.log('result',res)
      this.results = res;
    })
  }

}
