
        <div class="card-head">
            <button class="btn btn-info btn-sm pull-right" (click)="openModal(actionBom)">
               <i class="fa fa-plus" aria-hidden="true"></i> Nouveau
            </button>
        </div>

        <div class="card-body">
            <div class="row">
                <!-- <table class="table"> -->
                    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
                    <thead>
                        <tr>
                            <th *ngIf="entrepriseType  == '1'">Entreprise</th>
                            <th>Immatriculation</th>
                            <th>Nom</th>
                            <th>Marque</th>
                            <th>Commentaire</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listBom" class="pointer-cl">
                            <td (click)="emitDataBoms(item)" *ngIf="entrepriseType == '1'">{{item.entrepriseId != null ? item.entreprise.name:''}}</td> 
                            <td (click)="emitDataBoms(item)">{{item.immatriculation}}</td>
                            <td (click)="emitDataBoms(item)">{{item.name}}</td>
                            <td (click)="emitDataBoms(item)">{{item.band}}</td>
                            <td (click)="emitDataBoms(item)">{{item.comment}}</td>
                            <td>
                                <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


<ng-template #actionBom>
    <div class="modal-header">

        <h4 class="modal-title" *ngIf="checkAction === 'add'">Nouveau BOM</h4>
        <h4 class="modal-title" *ngIf="checkAction === 'update'">Modification BOM</h4>

        <button type="button" class="close" aria-label="Close" (click)="resetField()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Immatriculation</label>
            <input type="text" class="form-control" name="bomImmatriculation" [(ngModel)]="bomImmatriculation">
        </div>
        <div class="form-group">
            <label>Nom</label>
            <input type="text" class="form-control" name="bomName" [(ngModel)]="bomName">
        </div>
        <div class="form-group">
            <label>Marque</label>
            <input type="text" class="form-control" name="bomBand" [(ngModel)]="bomBand">
        </div>
        <div class="form-group" *ngIf="entrepriseType  == '1'">
            <label>Entreprise</label>
            <select name="idEntreprise" [(ngModel)]="idEntreprise" [ngModelOptions]="{standalone: true}" class="form-control">
                <option *ngFor="let entreprise of listEntreprise" value="{{entreprise.id}}">{{entreprise.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>Commentaire</label>
            <textarea type="text" class="form-control" name="bomComment" [(ngModel)]="bomComment"> </textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="checkAction === 'add'" type="button" class="btn btn-info" (click)="addBoms()"><i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter</button>
        <button *ngIf="checkAction === 'update'" type="button" class="btn btn-info" (click)="updateBoms()"><i class="fa fa-check-circle" aria-hidden="true"></i> Enregister</button>
    </div>
</ng-template>

<ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteBoms()">OUI</button>  
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>         
    </div>
</ng-template>
