import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { NgxCollapseModule } from 'ngx-collapse';



// ROUTE
import { RouteModule } from "../app/route/route.module";

// SERVICE
import { HttpClientModule } from '@angular/common/http';

// Module installe
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";

// component
import {
  AnomalyBacComponent,
  AnomalyComponent,
  BomComponent,
  CircuitComponent,
  DriverComponent,
  ReportComponent,
  RipeurComponent,
  LoginComponent,
  RegisterComponent,
  HomeComponent,
  MenuComponent
} from "../app/application";
import { CollectComponent } from './application/collect/collect.component';
import { TonnageComponent } from './application/tonnage/tonnage.component';
import { AffectationComponent } from './application/affectation/affectation.component';
import { EntrepriseComponent } from './application/entreprise/entreprise.component';
import { PointageComponent } from './application/pointage/pointage.component';
import { RapportjournalierComponent } from './application/rapportjournalier/rapportjournalier.component';
import { VidageComponent } from './application/vidage/vidage.component';
import { RapportleveebacComponent } from './application/rapportleveebac/rapportleveebac.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DownloadattachComponent} from "./application/downloadattach/downloadattach.component";

@NgModule({
  declarations: [
    AppComponent,
    AnomalyBacComponent,
    AnomalyComponent,
    BomComponent,
    CircuitComponent,
    DriverComponent,
    ReportComponent,
    RipeurComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    MenuComponent,
    CollectComponent,
    TonnageComponent,
    AffectationComponent,
    EntrepriseComponent,
    PointageComponent,
    VidageComponent,
    RapportjournalierComponent,
    RapportleveebacComponent,
    DownloadattachComponent
  ],
  imports: [
    ToastrModule.forRoot(),
    BrowserModule,
    RouteModule,
    HttpClientModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxCollapseModule

  ],
  providers: [
    DatePipe,
  {
    provide: LocationStrategy,
    //useClass: PathLocationStrategy
    useClass: HashLocationStrategy
  }
],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
