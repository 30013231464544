import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Ripeur } from "../constant/ripeur"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RipeurService {

  public apiUrl = environment.api

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }

  /**
   * getRipeurs
   */
  public getRipeurs(): Observable<Ripeur> {
    return this.httpClient.get<Ripeur>(
      this.apiUrl + 'ripeurs/getAll',
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getRipeursByEntreprise
   */
  public getRipeursByEntreprise(data): Observable<Ripeur> {
    return this.httpClient.post<Ripeur>(
      this.apiUrl + 'ripeurs/getAllByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * addRipeurs
   */
  public addRipeurs(data): Observable<Ripeur> {
    return this.httpClient.post<Ripeur>(
      this.apiUrl + 'ripeurs/add',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * updateRipeurs
   */
  public updateRipeurs(data): Observable<Ripeur> {
    return this.httpClient.post<Ripeur>(
      this.apiUrl + 'ripeurs/update',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteRipeurs
   */
  public deleteRipeurs(data): Observable<Ripeur> {
    return this.httpClient.post<Ripeur>(
      this.apiUrl + 'ripeurs/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
