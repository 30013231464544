import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Rapportleveebac} from "../constant/rapportleveebac"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RapportleveebacService {
  public apiUrl = environment.api;

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  /**
   * getRapportBac
   */
  public getRapportBac(limit): Observable<Rapportleveebac> {
    return this.httpClient.get<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getAllBoms(): Observable<any> {
    return this.httpClient.get<any>(
      this.apiUrl + 'rapportleveebac/getAllBoms',
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getBomsByEntreprise(data): Observable<any> {
    return this.httpClient.post<any>(
      this.apiUrl + 'rapportleveebac/getBomsByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }


  public downloadattachment1(): Observable<any> {
    return this.httpClient.get<any>(
      this.apiUrl + 'downloadattachment11',
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }
  /**
   * getRapportBacByEntreprise
   */
  public getRapportBacByEntreprise(data,limit): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getRapportBacBetweenTwoDatesbybom(data,limit): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/getRapportBacbybom/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getRapportBacBetweenTwoDatesByEntreprisebybom(data,limit): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/getRapportBacbyentreprisebybom/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }
  public excel(data): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/excel',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteRapportBac
   */
  public deleteRapportBac(data): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getRapportBacBetweenTwoDates
   */
  public getRapportBacBetweenTwoDates(data): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/betweenTwoDates',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getRapportBacBetweenTwoDatesByEntreprise(data): Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/betweenTwoDatesByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public supprimerPlusieursRapportBac(data):Observable<Rapportleveebac> {
    return this.httpClient.post<Rapportleveebac>(
      this.apiUrl + 'rapportleveebac/supprimerPlusieurs',
      JSON.stringify({arrayrapportleveebac:data}),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
