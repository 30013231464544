
    <script src="affectation.component.ts"></script>
    <script src="../anomaly/anomaly.component.ts"></script>
    <div *ngIf="a === false">
      <div style="margin-top: 20px" class="btn-periode" role="group" aria-label="Basic example">
        <button style="margin-left: 15px; margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(hier,hier)">Hier</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>
<!--        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="generatePdf('print')">Imprimer</button>-->
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="afficherBy2Date(debut,fin)">Afficher</button>
<!--        <button style="margin-right: 5px;" class="btn btn-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" >-->
<!--          Télécharger-->
<!--        </button>-->
<!--        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
<!--          <a class="dropdown-item" (click)="generatePdf('download')">Fichier PDF</a>-->
<!--          <a class="dropdown-item" (click)="generateExcel()">Fichier Excel</a>-->
<!--        </div>-->

        <input style="margin-right: 5px;" id="date" type="date" value="" name="debut" [(ngModel)]="debut">
        <input style="margin-right: 5px;" id="heure" type="time" value="" name="H debut" [(ngModel)]="Hdebut">
        <input style="margin-right: 5px;" id="date1" type="date" value="" name="fin" [(ngModel)]="fin">
        <input style="margin-right: 5px;" id="heure1" type="time" value="" name="H fin" [(ngModel)]="Hfin">
      </div>
        <div class="card-body">
<!--            <div class="row">-->
<!--              <div class="btn-group btn-periode" role="group" aria-label="Basic example">-->
<!--                <button type="button" class="btn btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>-->
<!--                <button type="button" class="btn btn-info" (click)="getBy2date(hier,hier)">Hier</button>-->
<!--                <button type="button" class="btn btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>-->
<!--                <button type="button" class="btn btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>-->
<!--              </div>-->
<!--              <div class="row periode" style="display: flex; flex-direction: row;">-->
<!--                <div class="col-sm-1">Du:</div>-->
<!--                <div class="col-sm-3"><input id="date" type="date" value="" name="debut" [(ngModel)]="debut"></div>-->
<!--                <div class="col-sm-1">A:</div>-->
<!--                <div class="col-sm-3"><input id="date1" type="date" value="" name="fin" [(ngModel)]="fin"></div>-->
<!--                <div class="col-sm-2">-->
<!--                  <button class="btn btn-info btn-sm pull-right" (click)="getBy2date(debut,fin)">-->
<!--                    <i class="fa fa-list" aria-hidden="true"></i> Afficher-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          <div class="row">
            <table  datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
              <thead>
              <tr>
                <!--                            <th></th>-->
                <th *ngIf="entrepriseType == '1'">Entreprise</th>
                <th>Date</th>
                <th>Circuit</th>
                <th>BOM</th>
                <th>Chauffeur</th>
                <th>Ripeur 1</th>
                <th>Ripeur 2</th>
                <th>Ripeur 3</th>
                <th>Heure de départ</th>
                <!--            <th></th>-->
              </tr>
              </thead>
              <tbody >
              <tr *ngFor="let item of listAffectation">
                <!--                          <td >-->
                <!--                            <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieursAffectation(item.collect?.id, $event)" >-->
                <!--                          </td>-->
                <td *ngIf="entrepriseType == '1'">{{item.collect.entrepriseId != null ? item.collect.entreprise.name:''}}</td>
                <td>{{item.collect?.createdAt | date:'yyyy-MM-dd'}}</td>
                <td>{{item.collect?.circuitdebut != null ? item.collect.circuitdebut:'----'}}</td>
                <td>{{item.collect?.bomId != null ? item.collect?.bom.immatriculation: '----'}}</td>
                <td>{{item.collect.driverId != null ? item.collect.driver.lastname+' '+item.collect.driver.firstname:'----'}}</td>
                <td>{{item.collect.lastnameripeur1 != null ? item.collect.lastnameripeur1+' '+item.collect.firstnameripeur1 : '----'}}</td>
                <td>{{item.collect.lastnameripeur2 != null ? (item.collect.lastnameripeur2+' '+item.collect.firstnameripeur2) : '----'}}</td>
                <td>{{item.collect.lastnameripeur3 != null ? (item.collect.lastnameripeur3+' '+item.collect.firstnameripeur3) : '----'}}</td>
                <td>{{item.collect.createdAtBegin != null ? (item.collect.createdAtBegin | date:'HH:mm:ss') : '----'}}</td>
                <!--                        <td>-->
                <!--                            <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.collect.id)">-->
                <!--                                <i class="fas fa-trash"></i>-->
                <!--                            </button>-->
                <!--                        </td>-->
              </tr>
              </tbody>
            </table>
            </div>
        </div>
    </div>






    <div *ngIf="a === false">
      <div class="card-body">
        <h2 style="color: #007E3A; font-weight: bold;">Configuration e-mail pour envoyer le rapport</h2>
        <div *ngIf="entrepriseType != '1'">
          <h2 style="color: #1492ca; font-weight: normal; margin-top: 10px;">Importer le logo</h2>
          <div *ngIf="logo != '' && logo != null">
            <img alt="logo" src="{{urlimage}}" style="width: 200px; height: 200px; padding:15px; borderRadius:10px; margin-bottom: 30px;"/>
          </div>
          <div class="file-upload">
            <input type="file" id="image"  #UploadFileInput name="uploadedImage" placeholder="Photo" (change)="onFileSelect($event)" >
          </div>
          <div class="form-group" style="width: 40%; margin-top: 25px;">
            <label>Objet de l' e-mail</label>
            <input type="text" class="form-control" name="bomImmatriculation" [(ngModel)]="objetEmail">
          </div>
          <button style="margin-bottom: 15px; margin-top: 15px;color:#ffffff; padding-left: 30px; padding-right: 30px;" class="btn btn-warning btn-sm pull-right" (click)="sendFile()">
            Enregistrer
          </button>
        </div>

        <div class="card-head">
          <button style="margin-bottom: 15px; margin-top: 30px;" class="btn btn-info btn-sm pull-right" (click)="openModal(actionEmail)">
            <i class="fa fa-plus" aria-hidden="true"></i> Ajouter un e-mail
          </button>
        </div>
        <div class="row">
           <table class="table">
            <thead>
            <tr>
              <th *ngIf="entrepriseType == '1'">Entreprise</th>
              <th>Email</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of listEmail">
              <td (click)="emitDataEmail(item)" *ngIf="entrepriseType == '1'">{{item.entrepriseId != null ? item.entreprise.name :''}}</td>
              <td (click)="emitDataEmail(item)">{{item.email != null ? item.email : ''}}</td>
              <td>
                  <button class="btn btn-sm btn-danger" (click)="beforeDeleteEmail(item.id)">
                      <i class="fas fa-trash"></i>
                  </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button *ngIf="entrepriseType != '1'" class="btn btn-sm btn-success" style="margin-bottom: 15px; margin-left: 15px;" (click)="envoyerEmailManually()">
        Envoyer l'e-mail
      </button>
<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <div class="form-group">-->
<!--        <label>Objet de l'e-mail</label>-->
<!--        <input type="text" class="form-control" name="objetEmail" [(ngModel)]="objetEmail">-->
<!--      </div>-->
    </div>

<div *ngIf="a === true"><span (click)="bb()"> bonjour </span></div>

<ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteAffectation()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
</ng-template>

    <ng-template #confirmDeleteEmail>
      <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteEmail()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
      </div>
    </ng-template>



<ng-template #openCart>
    <div class="card-body">
        <div id="map" style="width:100%;height:400px"></div>
    </div>
</ng-template>



    <ng-template #actionEmail>
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="checkAction === 'add'">Ajout e-mail</h4>
        <h4 class="modal-title" *ngIf="checkAction === 'update'">Modification e-mail</h4>

        <div type="button" class="close" aria-label="Close" (click)="resetField()">
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>E-mail</label>
          <input type="text" class="form-control" name="bomImmatriculation" [(ngModel)]="emailName">
        </div>
        <div class="form-group" *ngIf="entrepriseType  == '1'">
          <label>Entreprise</label>
          <select name="idEntreprise" [(ngModel)]="idEntreprise" [ngModelOptions]="{standalone: true}" class="form-control">
            <option *ngFor="let entreprise of listEntreprise" value="{{entreprise.id}}">{{entreprise.name}}</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="checkAction === 'add'" type="button" class="btn btn-info" (click)="addEmail()"><i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter</button>
        <button *ngIf="checkAction === 'update'" type="button" class="btn btn-info" (click)="updateEmail()"><i class="fa fa-check-circle" aria-hidden="true"></i> Enregister</button>
      </div>
    </ng-template>
