<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <form class="login100-form validate-form">
                <span class="login100-form-title p-b-43">
                    Connectez-vous
                </span>
<!--                <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">-->
<!--                    <select placeholder="Mot de passe" class="inputEntreprise" name="idEntreprise" [(ngModel)]="idEntreprise" [ngModelOptions]="{standalone: true}">-->
<!--                        <option *ngFor="let entreprise of listEntreprise" value="{{entreprise.id}}">{{entreprise.name}}</option>-->
<!--                    </select>-->
<!--                </div>-->
              <div class="wrap-input100 validate-input" data-validate="username is required">
                <input class="input100" type="text" name="username" [(ngModel)]="username"
                       placeholder="Login">
                <span class="focus-input100"></span>
                <span class="label-input100">
                        <i class="fas fa-user"
                           style="margin-left: -2px;font-size: medium;margin-right: 15px; float: right; margin-top: -10px"></i>
                    </span>
              </div>

                <div class="wrap-input100 validate-input" data-validate="Password is required">
                    <input class="input100" type="password" name="password" [(ngModel)]="password"
                        placeholder="Mot de passe">
                    <span class="focus-input100"></span>
                    <span class="label-input100">
                        <i class="fas fa-key"
                            style="margin-left: -2px;font-size: medium;margin-right: 15px; float: right; margin-top: -10px"></i>
                    </span>
                </div>
                <div class="help-block"><span class="error">{{help_block}}</span></div>

                <div class="container-login100-form-btn" style="margin-top: 50px;">
                    <button class="login100-form-btn" (click)="login()">
                        connexion
                    </button>
                </div>
              <div style="width: 100%;display: flex; justify-content: flex-end; align-items: flex-end;">
                <div class="" (click)="emitDataEmail()" style="cursor:pointer; font-size: medium; margin-top: 15px; margin-right: 20px;">Récuperer le mot de passe?</div>
              </div>
            </form>
            <div style="display:flex; flex:1;background-color:#c8d1d8; align-items: center; justify-content: center;">
            <!-- <div class="login100-more" style="background-image: url('../../../../assets/images/1_Iconesignalgps.png');width: 350px; height: 350px;">
            </div> -->
            <!-- <img class=""   src="../../../../assets/images/1_Iconesignalgps.png" style="width: 420px; height: 400px;"> -->
            <img class=""   src="../../../../assets/images/Accueil_console WC.png" style="width: 110%; height: 100%;">
           <!-- <div class="login100-more" style="background-image: url('../../../../assets/images/Accueil_console WC.png');width: 90%; height: 90%;">
            </div>    -->
        </div>
        </div>
    </div>
</div>


<ng-template #actionEmail>
  <div class="modal-header" style="padding-left: 15px; padding-right: 15px;">
    <h4 class="modal-title" style="margin-top: 12px;margin-bottom: 12px;">Récupération de mot de passe</h4>
    <div  class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="false">&times;</span>
    </div>
  </div>
  <div class="modal-body" style="padding-left: 15px; padding-right: 15px;">
    <div class="form-group" style="margin-top: 20px;">
      <label>Email :</label>
      <input style="margin-top: 15px;" type="text" class="form-control" name="emails" [(ngModel)]="emailpassword">
    </div>
    <center style="margin-bottom: 12px;"><div *ngIf="messageemailinvalideOrDejaInBase !== ''">
      <h5 style="color: red;">{{messageemailinvalideOrDejaInBase}}</h5>
    </div></center>
  </div>
  <div class="modal-footer" style="margin: 15px;">
    <button type="button" class="" style="background-color: #1da1f2; color: white; margin-top: 12px; padding: 15px; border-radius: 6px;" (click)="envoieMailPassword()"> <i class="fa fa-check-circle" aria-hidden="true"></i> Récuperer</button>
  </div>
</ng-template>
