import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Pointing} from "../constant/pointing"
import { retry, catchError } from 'rxjs/operators'
import {Email} from "../constant/email";

@Injectable({
  providedIn: 'root'
})
export class PointingService {

  public apiUrl = environment.api;

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  public date_aujour_d_hui(){
    var ladate=new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }
  public date_lendemain(){
    const today = new Date();
    const tomorrow = new Date();
    return tomorrow.setDate(today.getDate()-1);
  }

  public less_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - nbr));
    return this.formatage_date(dateEnd);
  }

  public formatage_date(imput_date){
    var ladate = imput_date;
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }

  public add_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() + nbr));
    return this.formatage_date(dateEnd);
  }



  /**
   * getPointings
   */
  public getPointings(limit): Observable<Pointing> {
    return this.httpClient.get<Pointing>(
      this.apiUrl + 'pointings/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }


  public excel(data): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'pointings/excel',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getPointingsByEntreprise
   */
  public getPointingsByEntreprise(data,limit): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'pointings/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getPointingsBetweenTwoDates
   */
  public getPointingsBetweenTwoDates(data): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'pointings/betweenTwoDates',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getPointingsBetweenTwoDatesByEntreprise
   */
  public getPointingsBetweenTwoDatesByEntreprise(data): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'pointings/betweenTwoDatesByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deletePointings
   */
  public deletePointings(data): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'pointings/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }


  public supprimerPlusieursPointages(data): Observable<Pointing> {
    return this.httpClient.post<Pointing>(
      this.apiUrl + 'pointings/supprimerPlusieurs',
      JSON.stringify({arrayPointages:data}),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
