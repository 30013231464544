import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { EntrepriseService } from "../../services/entreprise.service";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.css']
})
export class EntrepriseComponent implements OnInit, OnChanges {
  @ViewChild('actionEntreprise') actionEntreprise: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listEntreprise: any = [];
  public tokenFromUI: string = "0123456789123456";
  public encrypted: string;
  public decrypted: string;
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public entrepriseId: any;
  public entrepriseName: any;
  public entrepriseEmail: any;
  public entrepriseAdress: any;
  public entreprisePassword: any;
  public entrepriseType: any;
  // check action
  public checkAction: any;

  constructor(
    private entrepriseService: EntrepriseService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
  }

  ngOnChanges(): void {
    this.loadEntreprise()
  }

  /**
   * loadEntreprise
   */
  public loadEntreprise(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.decryptUsingAES256(this.entreprisePassword);
      this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data;
        this.spinner.hide();
        console.log('listRipeur', this.listEntreprise);
        this.dtTigger.next()
      })
    })
  }

  /**
   * emitDataEntreprise
   */
  public async emitDataEntreprise(item: any) {
    this.checkAction = 'update';
    console.log('emit data for action', item);
    this.emitData.emit(item);
    this.entrepriseId = item.id;
    this.entrepriseName = item.name;
    this.entrepriseEmail = item.email;
    this.entrepriseAdress = item.adress;
    this.entreprisePassword = this.decryptUsingAES256(item.password);
    this.entrepriseType = item.type;
    await <any>this.ngbModal.open(this.actionEntreprise)
  }

   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.entrepriseId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

   /**
   * addEntreprise
   */
  public addEntreprise() {
    console.log(' debut : '+this.entreprisePassword);
    console.log(' scrité: '+this.encrypted);
    let body = {
      name: this.entrepriseName,
      email: this.entrepriseEmail,
      adress: this.entrepriseAdress,
      password: this.entreprisePassword,
      type: this.entrepriseType
    };
     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.entrepriseService.addEntreprise(body)
      .subscribe((res: any) => {
        this.loadEntreprise();
        this.resetField();
        this.spinner.hide();
      })
  }

   /**
   * updateEntreprise
   */
  public updateEntreprise(): void{
    console.log(' debut : '+this.entreprisePassword);
    console.log(' scrité: '+this.encrypted);
    let body = {
      id: this.entrepriseId,
      name: this.entrepriseName,
      email: this.entrepriseEmail,
      adress: this.entrepriseAdress,
      password: this.encryptUsingAES256(this.entreprisePassword),
      type: this.entrepriseType
    };
     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.entrepriseService.updateEntreprise(body)
      .subscribe((res: any) => {
        this.loadEntreprise();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * deleteEntreprise
   */
  public deleteEntreprise() {
    let body = {
      id: this.entrepriseId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseService.deleteEntreprise(body)
      .subscribe((res: any) => {
        this.loadEntreprise();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.checkAction = 'add';
    this.ngbModal.open(modal)
  }

   // GEstion field data
   public resetField() {
    this.ngbModal.dismissAll();
    this.entrepriseName = '';
    this.entrepriseEmail = '';
    this.entrepriseAdress = '';
    this.entreprisePassword = '';
    this.entrepriseType = ''
  }

  public  encryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(code), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return  this.encrypted
  }

  public decryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(
      code, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

      return this.decrypted.slice(1,this.decrypted.length - 1)
  }

}
