import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Rapportjornalier} from "../constant/rapportjournalier"
import {retry, catchError, map} from 'rxjs/operators'
import {Collect} from "../constant/collect";
import {Pointing} from "../constant/pointing";
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class TimezoneService {

    public apiUrl = "https://maps.googleapis.com";
    public APIkey = "AIzaSyB-dlb9jrfcNAscqPWl_yUiwbusE4mi890";

    constructor(
        private httpClient: HttpClient
    ) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        })
    }

    /**
     * getRapportjornalier
     */
    public getTimeZone(location): Observable<any> {
        const timestamp = moment().unix();
        return this.httpClient.get<any>(
            this.apiUrl + '/maps/api/timezone/json?location='+
            location+'&timestamp='+timestamp+
            '&key='+this.APIkey,
            this.httpOptions
        ).pipe(map((res:any)=>{
            ////type signe 0: '-' , 1: '+'
            if(res.rawOffset < 0){
                return { typeSigne: 0 , offset: res.rawOffset / 3600}
            }else{
                return { typeSigne: 1 , offset: res.rawOffset / 3600}
            }
        }), catchError(this.handleError))
    }

    // Error handling
    public handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(['Erreur HTTP', errorMessage]);
        return throwError(errorMessage);
    }
}
