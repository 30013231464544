import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Circuit } from "../constant/circuit"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CircuitService {

  public apiUrl = environment.api

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }

   /**
   * getCircuits
   */
  public getCircuits(): Observable<Circuit> {
    return this.httpClient.get<Circuit>(
      this.apiUrl + 'circuits/getAll',
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getRipeursByEntreprise
   */
  public getCircuitsByEntreprise(data): Observable<Circuit> {
    return this.httpClient.post<Circuit>(
      this.apiUrl + 'circuits/getAllByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * addCircuits
   */
  public addCircuits(data): Observable<Circuit> {
    return this.httpClient.post<Circuit>(
      this.apiUrl + 'circuits/add',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * updateCircuits
   */
  public updateCircuits(data): Observable<Circuit> {
    return this.httpClient.post<Circuit>(
      this.apiUrl + 'circuits/update',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteCircuits
   */
  public deleteCircuits(data): Observable<Circuit> {
    return this.httpClient.post<Circuit>(
      this.apiUrl + 'circuits/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
