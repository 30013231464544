import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Rapportjornalier} from "../constant/rapportjournalier"
import { retry, catchError } from 'rxjs/operators'
import {Collect} from "../constant/collect";
import {Pointing} from "../constant/pointing";

@Injectable({
  providedIn: 'root'
})
export class VidageService {

  public apiUrl = environment.api;

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }

  /**
   * getRapportjornalier
   */
  public getRapportjornaliers(limit): Observable<Rapportjornalier> {
    return this.httpClient.get<Rapportjornalier>(
      this.apiUrl + 'vidage/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public excel(data): Observable<Rapportjornalier> {
    return this.httpClient.post<Rapportjornalier>(
      this.apiUrl + 'vidage/excel',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getRapportjornalierByEntreprise
   */
  public getRapportjornalierByEntreprise(data,limit): Observable<Rapportjornalier> {
    return this.httpClient.post<Rapportjornalier>(
      this.apiUrl + 'vidage/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteRapportjornalier
   */
  public deleteRapportjornalier(data): Observable<Rapportjornalier> {
    return this.httpClient.post<Rapportjornalier>(
      this.apiUrl + 'vidage/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getBetweenTwoDates(data): Observable<Rapportjornalier>{
    return this.httpClient.post<Rapportjornalier>(
      this.apiUrl + 'vidage/betweenTwoDates',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getBetweenTwoDatesByEntreprise(data): Observable<Rapportjornalier> {
    return this.httpClient.post<Rapportjornalier>(
      this.apiUrl + 'vidage/betweenTwoDatesByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }


  public supprimerPlusieursRapportjournalier(data):Observable<Rapportjornalier> {
    return this.httpClient.post<Rapportjornalier>(
      this.apiUrl + 'vidage/supprimerPlusieurs',
      JSON.stringify({arrayRapportjornalier:data}),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
