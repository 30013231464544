import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { RipeurService } from '../../services/ripeur.service';
import { EntrepriseService } from "../../services/entreprise.service";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-ripeur',
  templateUrl: './ripeur.component.html',
  styleUrls: ['./ripeur.component.css']
})
export class RipeurComponent implements OnInit, OnChanges {
  @ViewChild('actionRipeur') actionRipeur: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listRipeur: any = [];
  public listEntreprise: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public ripeurId: any;
  public ripeurImmatricule: any;
  public ripeurFirstname: any;
  public ripeurLastname: any;
  public ripeurAddress: any;
  public ripeurPhone: any;
  public idEntreprise: any;
  // check action
  public checkAction: any;
  public entrepriseType : any;

  constructor(
    private ripeurService: RipeurService,
    private entrepriseService: EntrepriseService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };

    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseType = localStorage.getItem('entrepriseType');
      if(this.entrepriseType == "1"){
        this.ripeurService.getRipeurs()
        .subscribe((res: any) => {
          this.listRipeur = res.data;
          this.spinner.hide();
          this.dtTigger.next()
      })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.ripeurService.getRipeursByEntreprise(body)
          .subscribe((res: any) => {
            this.listRipeur = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
      this.entrepriseService.getEntreprises()
      .subscribe((res: any) => {
        this.listEntreprise = res.data
      })
  }

  ngOnChanges(): void {
    this.loadRipeur()
  }


  /**
   * loadRipeur
   */
  public loadRipeur(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.ripeurService.getRipeurs()
        .subscribe((res: any) => {
          this.listRipeur = res.data;
          this.spinner.hide();
          this.dtTigger.next()
      })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        }
        this.ripeurService.getRipeursByEntreprise(body)
          .subscribe((res: any) => {
            this.listRipeur = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }

  /**
   * emitDataRipeurs
   */
  public async emitDataRipeurs(item: any) {
    this.checkAction = 'update';
    this.emitData.emit(item);
    this.ripeurId = item.id;
    this.ripeurFirstname = item.firstname;
    this.ripeurLastname = item.lastname;
    this.ripeurImmatricule = item.immatricule;
    this.ripeurAddress = item.address;
    this.ripeurPhone = item.phone;
    this.idEntreprise = item.entrepriseId;
    await <any>this.ngbModal.open(this.actionRipeur)
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.ripeurId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  /**
   * addRipeurs
   */
  public addRipeurs() {
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      firstname: this.ripeurFirstname,
      lastname: this.ripeurLastname,
      immatricule: this.ripeurImmatricule,
      address: this.ripeurAddress,
      phone: this.ripeurAddress,
      entrepriseId: id_E,
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.ripeurService.addRipeurs(body)
      .subscribe((res: any) => {
        this.loadRipeur();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * updateRipeurs
   */
  public updateRipeurs(): void{
    let id_E = "";
    if(this.entrepriseType == "1"){
      id_E = this.idEntreprise
    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let body = {
      id: this.ripeurId,
      firstname: this.ripeurFirstname,
      lastname: this.ripeurLastname,
      immatricule: this.ripeurImmatricule,
      address: this.ripeurAddress,
      phone: this.ripeurPhone,
      entrepriseId: id_E,
    };

    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.ripeurService.updateRipeurs(body)
      .subscribe((res: any) => {
        this.loadRipeur();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * deleteRipeur
   */
  public deleteRipeurs() {
    let body = {
      id: this.ripeurId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.ripeurService.deleteRipeurs(body)
      .subscribe((res: any) => {
        console.log(res);
        this.loadRipeur();
        this.resetField();
        this.spinner.hide();
      })
  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.checkAction = 'add';
    this.ngbModal.open(modal)
  }

   // GEstion field data
   public resetField() {
    this.ngbModal.dismissAll();
    this.ripeurImmatricule = '';
    this.ripeurFirstname = '';
    this.ripeurLastname = '';
    this.ripeurPhone = '';
    this.ripeurAddress = '';
    this.idEntreprise = ''
  }
}
