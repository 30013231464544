export * from './downloadattach/downloadattach.component'
export * from './anomaly/anomaly.component'
export * from './anomaly-bac/anomaly-bac.component'
export * from './bom/bom.component'
export * from './circuit/circuit.component'
export * from './driver/driver.component'
export * from './ripeur/ripeur.component'
export * from './user/login/login.component'
export * from './user/register/register.component'
export * from './report/report.component'
export * from './home/home.component'
export * from './menu/menu.component'
export * from './collect/collect.component'
export * from './tonnage/tonnage.component'
export * from './affectation/affectation.component'
export * from './entreprise/entreprise.component'
export * from './vidage/vidage.component'
