import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Entreprise } from "../constant/entreprise"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class EntrepriseService {
  public apiUrl = environment.api
  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }

  /**
   * getEntreprises
   */
  public getEntreprises(): Observable<Entreprise> {
    return this.httpClient.get<Entreprise>(
      this.apiUrl + 'entreprises/getAll',
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * addEntreprise
   */
  public addEntreprise(data): Observable<Entreprise> {
    return this.httpClient.post<Entreprise>(
      this.apiUrl + 'entreprises/add',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * updateEntreprise
   */
  public updateEntreprise(data): Observable<Entreprise> {
    return this.httpClient.post<Entreprise>(
      this.apiUrl + 'entreprises/update',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteEntreprise
   */
  public deleteEntreprise(data): Observable<Entreprise> {
    return this.httpClient.post<Entreprise>(
      this.apiUrl + 'entreprises/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public login(data): Observable<Entreprise> {
    return this.httpClient.post<Entreprise>(
      this.apiUrl + 'entreprises/login',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
