import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// CMP
import {
  AnomalyBacComponent,
  AnomalyComponent,
  BomComponent,
  CircuitComponent,
  DriverComponent,
  HomeComponent,
  LoginComponent,
  ReportComponent,
  RipeurComponent,
  CollectComponent,
  EntrepriseComponent,
  DownloadattachComponent
  
} from '../application';

// ROUTE
const routes: Routes = [
  // { path: 'downloadattachment1', redirectTo: 'downloadattachment1'},
  // { path: 'downloadattachment1', component: DownloadattachComponent },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'downloadattachment1', component: DownloadattachComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'front/login', component: LoginComponent },
  { path: 'front/home', component: HomeComponent },
  { path: 'driver', component: DriverComponent },
  { path: 'report', component: ReportComponent },
  { path: 'ripeur', component: RipeurComponent },
  { path: 'bom', component: BomComponent },
  { path: 'anomaly', component: AnomalyComponent },
  { path: 'bac', component: AnomalyBacComponent },
  { path: 'circuit', component: CircuitComponent },
  { path: 'collect', component: CollectComponent },
  { path: 'entreprise', component: EntrepriseComponent },
  { path: '**', redirectTo: 'LoginComponent'}

]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class RouteModule { }
