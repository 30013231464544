import { Component, OnInit,EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnChanges {
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listReport: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public entrepriseType : any;
  public reportId: any;
  public emitData: EventEmitter<any> = new EventEmitter;

  constructor(
    private reportService: ReportService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseType = localStorage.getItem('entrepriseType');
    if(this.entrepriseType == "1"){
      this.reportService.getReports()
      .subscribe((res: any) => {
        this.listReport = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.reportService.getReportsByEntreprise(body)
          .subscribe((res: any) => {
            this.listReport = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })

      }
  }

  ngOnChanges(): void {
    this.loadReport()
  }


  /**
   * loadReport
   */
  public loadReport(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
      this.reportService.getReports()
      .subscribe((res: any) => {
        this.listReport = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        }
        this.reportService.getReportsByEntreprise(body)
          .subscribe((res: any) => {
            this.listReport = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })

      }
    })
  }

   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.reportId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * deleteReports
   */
  public deleteReports() {
    let body = {
      id: this.reportId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.reportService.deleteReports(body)
      .subscribe((res: any) => {
        this.loadReport();
        this.resetField();
        this.spinner.hide();
      })
  }


  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
  }
}
