import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { Tonnage} from "../constant/tonnage"
import { retry, catchError } from 'rxjs/operators'
import {Pointing} from "../constant/pointing";
import {Collect} from "../constant/collect";

@Injectable({
  providedIn: 'root'
})
export class TonnageService {
  public apiUrl = environment.api;

  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };

  /**
   * getTonnage
   */
  public getTonnage(limit): Observable<Tonnage> {
    return this.httpClient.get<Tonnage>(
      this.apiUrl + 'tonnage/getAll/'+limit,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getTonnageByEntreprise
   */
  public getTonnageByEntreprise(data,limit): Observable<Tonnage> {
    return this.httpClient.post<Tonnage>(
      this.apiUrl + 'tonnage/getAllByEntreprise/'+limit,
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public excel(data): Observable<Collect> {
    return this.httpClient.post<Collect>(
      this.apiUrl + 'tonnage/excel',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  /**
   * deleteTonnage
   */
  public deleteTonnage(data): Observable<Tonnage> {
    return this.httpClient.post<Tonnage>(
      this.apiUrl + 'tonnage/delete',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  /**
   * getTonnageBetweenTwoDates
   */
  public getTonnageBetweenTwoDates(data): Observable<Tonnage> {
    return this.httpClient.post<Tonnage>(
      this.apiUrl + 'tonnage/betweenTwoDates',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public getTonnageBetweenTwoDatesByEntreprise(data): Observable<Tonnage> {
    return this.httpClient.post<Tonnage>(
      this.apiUrl + 'tonnage/betweenTwoDatesByEntreprise',
      JSON.stringify(data),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  public supprimerPlusieursTonnage(data):Observable<Tonnage> {
    return this.httpClient.post<Tonnage>(
      this.apiUrl + 'tonnage/supprimerPlusieurs',
      JSON.stringify({arrayTonnage:data}),
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
