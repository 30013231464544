import {Component, EventEmitter, Inject, LOCALE_ID, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Anomalies_bacService} from '../../services/anomalies_bac.service';
import {PdfService} from '../../services/pdf.service';
import {DatatableLanguage} from "../../constant/french-datatable";
import {Subject} from "rxjs";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from 'angular-datatables';
import {DomSanitizer} from '@angular/platform-browser';
import {NgxSpinnerService} from "ngx-spinner";
import moment from 'moment';
import {PointingService} from "../../services/pointing.service";

@Component({
  selector: 'app-anomaly-bac',
  templateUrl: './anomaly-bac.component.html',
  styleUrls: ['./anomaly-bac.component.css']
})
export class AnomalyBacComponent implements OnInit, OnChanges {
  @ViewChild('confirmCheckDeletePlsAnomaliesbacs') confirmCheckDeletePlsAnomaliesbacs: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild('openCart') openCart: any;
  //  @ViewChild('map') mapElement: any;
  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  public listAnomalies_bac: any = [];
  public tab = [];
  public resTransformTab = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public anomaly_bacId: any;
  public entrepriseType: any;
  public emitData: EventEmitter<any> = new EventEmitter;
  public debut: any;
  public Hdebut: any;
  public fin: any;
  public Hfin: any;
  public aujourdui: any;
  public hier: any;
  public debut_semaine: any;
  public fin_semaine: any;
  public debut_mois: any;
  public fin_mois: any;
  private limit: number = 50;
  public checkActiondemande: any;
  public checkEnableDisablePlusieursAnomaliesbacsArray: any = [];

  constructor(
    private pointingService: PointingService,
    private anomalies_bacService: Anomalies_bacService,
    private ngbModal: NgbModal,
	  private sanitizer:DomSanitizer,
    private pdfService: PdfService,
    private spinner: NgxSpinnerService,
    @Inject(LOCALE_ID) private locale: string
  ) { }



  ngOnInit(): void {
    this.aujourdui = this.pointingService.date_aujour_d_hui();
    this.hier = this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(),1);
    this.traitement_semaine();
    this.traitement_mois();
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 0, 'desc' ]]
    };
    this.entrepriseType = localStorage.getItem('entrepriseType');
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    if(this.entrepriseType == "1"){
      this.anomalies_bacService.getAnomalies_bac(this.limit)
      .subscribe((res: any) => {
            let localAnomalieBac = res.data;
			for (let index = 0; index < localAnomalieBac.length; index++) {
			  let resTranform  = this.transform(localAnomalieBac[index].urlPhoto);
			  let ici = localAnomalieBac[index];
			  ici.image_ = resTranform;
			  this.listAnomalies_bac.push(ici);
			}
			// this.listAnomalies_bac = this.listAnomalies_bac;
        this.spinner.hide();
        this.dtTigger.next()
      })
    }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.anomalies_bacService.getAnomalies_bacByEntreprise(body,this.limit)
        .subscribe((res: any) => {
              let localAnomalieBac = res.data;
			for (let index = 0; index < localAnomalieBac.length; index++) {
			  let resTranform  = this.transform("data:image/png;base64,"+localAnomalieBac[index].urlPhoto);
			  let ici = localAnomalieBac[index];
			  ici.image_ = resTranform;
			  this.listAnomalies_bac.push(ici);
			}
			// this.listAnomalies_bac = this.listAnomalies_bac;
      if(body.entrepriseId === "2"){
        this.Hdebut = '00:00';
        this.Hfin = '23:59'
        this.debut = this.pointingService.date_aujour_d_hui();
        this.fin = this.pointingService.date_aujour_d_hui();
      }else if(body.entrepriseId === "3"){
        this.Hdebut = '17:00';
        this.Hfin = '16:00'
        this.debut= moment.utc(this.pointingService.date_lendemain()).format("yyyy-MM-DD");
        this.fin = this.pointingService.date_aujour_d_hui();
      
    }
          this.spinner.hide();
          this.dtTigger.next()
        })
    }
  }

  transform(base64Image){
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
}



  public traitement_semaine(){
    var ladate=new Date();
    var position = ladate.getDay();
    this.debut_semaine =  this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(), position);
    this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine,6);
  }



  public traitement_mois(){
    var mois = this.aujourdui.slice(5,7);
    var annees = this.aujourdui.slice(0,4);
    var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    this.debut_mois = this.aujourdui.slice(0,8)+'01';
    this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  }

  ngOnChanges(): void {
    this.loadAnomalies_bac()
  }

  generatePdf(type: string) {
    // this.pdfService.generatePdf(this.listAnomalies_bac, type);
     this.pdfService.generatePdf(this.listAnomalies_bac, type, this.locale);
  }
  /**
   * loadAnomalies_bac
   */
  public loadAnomalies_bac(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if (this.entrepriseType == "1") {
        this.anomalies_bacService.getAnomalies_bac(this.limit)
        .subscribe((res: any) => {
          let localAnomalieBac = res.data;
			for (let index = 0; index < localAnomalieBac.length; index++) {
			  let resTranform  = this.transform(localAnomalieBac[index].urlPhoto);
			  let ici = localAnomalieBac[index];
			  ici.image_ = resTranform;
			  this.listAnomalies_bac.push(ici);
			}
			// this.listAnomalies_bac = this.listAnomalies_bac;
          this.spinner.hide();
          this.dtTigger.next()
        })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.anomalies_bacService.getAnomalies_bacByEntreprise(body,this.limit)
          .subscribe((res: any) => {
                let localAnomalieBac = res.data;
			for (let index = 0; index < localAnomalieBac.length; index++) {
			  let resTranform  = this.transform(localAnomalieBac[index].urlPhoto);
			  let ici = localAnomalieBac[index];
			  ici.image_ = resTranform;
			  this.listAnomalies_bac.push(ici);
			}
			      // this.listAnomalies_bac = this.listAnomalies_bac;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.anomaly_bacId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  /**
   * beforeDelete
   */
  public openMap(latitude,long) {
    this.ngbModal.open(this.openCart);
    const mapProperties = {
      center: new google.maps.LatLng(latitude, long),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    //this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    this.map = new google.maps.Map(document.getElementById("map"), mapProperties);
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, long),
      map: this.map,
      title: 'markers'
    });
    google.maps.event.trigger(this.map, "resize")
    //
  }


  /**
   * deleteAnomalies_bac
   */
  public deleteAnomalies_bac() {
    let body = {
      id: this.anomaly_bacId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.anomalies_bacService.deleteAnomalies_bac(body)
      .subscribe((res: any) => {
        this.loadAnomalies_bac();
        this.resetField();
        this.spinner.hide();
      })
  }

  public getBy2date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_
        };
        this.anomalies_bacService.getAnnomalyBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listAnomalies_bac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        // let bodyE = {
        //   entrepriseId: localStorage.getItem('entrepriseId'),
        //   startDate: start,
        //   endDate: this.pointingService.add_day_date(end, 1)
        // };
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };

        this.anomalies_bacService.getAnnomalyBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listAnomalies_bac = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }



  public supprimerPlusieursAnomaliesbacs() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.anomalies_bacService.supprimerPlusieursAnomaliesbacs(this.checkEnableDisablePlusieursAnomaliesbacsArray)
      .subscribe((res: any) => {
        this.loadAnomalies_bac();
        this.resetField();
        this.spinner.hide()
      })
  }

  public openModaPourSupprimerPlusieursAnomaliesbacs() {
    this.checkActiondemande = 'add';
    this.ngbModal.open(this.confirmCheckDeletePlsAnomaliesbacs)
  }

  public checkEnableDisablePlusieursAnomaliesbacs(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursAnomaliesbacsArray.length; i++){
        if(this.checkEnableDisablePlusieursAnomaliesbacsArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursAnomaliesbacsArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursAnomaliesbacsArray = this.checkEnableDisablePlusieursAnomaliesbacsArray.filter(function (item) {
        return item !== id;
      });
    }
  }


  generateExcel(){
    let id_E = "";
    if(this.entrepriseType == "1"){

    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let listAnomalies_bacExcel = [];
    if(this.listAnomalies_bac.length > 0) {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      for (let i = 0; i < this.listAnomalies_bac.length; i++) {
        const item = this.listAnomalies_bac[i];
        if (item.createdAt !== null && item.createdAt !== undefined) {
          //var tttt = moment(item.createdAt).subtract(6, 'h');
          item.createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        }
        listAnomalies_bacExcel.push(item);
      }
      let body = {
        listAnomalies_bacExcel: listAnomalies_bacExcel,
        id_E: id_E
      };

      this.anomalies_bacService.excel(body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = res.url;
            document.body.appendChild(a);
            a.click();
            this.loadAnomalies_bac();
            this.resetField();
            this.spinner.hide();
            document.body.removeChild(a);
          }
        })
    }
  }



  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
  }
}
