<script src="collect.component.ts">
</script>
<script src="../anomaly/anomaly.component.ts"></script>
<div *ngIf="a === false">
    <div style="margin-top: 15px" class="btn-periode" role="group" aria-label="Basic example">
        <button style="margin-left: 15px; margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(hier,hier)">Hier</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="generatePdf('print')">Imprimer</button>
        <button style="margin-right: 5px;" type="button" class="btn btn-sm btn-info" (click)="getBy2date(debut,fin)">Afficher</button>
        <button style="margin-right: 5px;" class="btn btn-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
      Télécharger
    </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="generatePdf('download')">Fichier PDF</a>
            <a class="dropdown-item" (click)="generateExcel()">Fichier Excel</a>
        </div>

        <input style="margin-right: 5px;" id="date" type="date" value="" name="debut" [(ngModel)]="debut">
        <input style="margin-right: 5px;" id="heure" type="time" value="" name="H debut" [(ngModel)]="Hdebut">
        <input style="margin-right: 5px;" id="date1" type="date" value="" name="fin" [(ngModel)]="fin">
        <input style="margin-right: 5px;" id="heure1" type="time" value="" name="H fin" [(ngModel)]="Hfin">
    </div>

    <div class="card-body">
        <!--    <div class="row">-->
        <!--      <div class="btn-group btn-periode" role="group" aria-label="Basic example">-->
        <!--        <button type="button" class="btn btn-info" (click)="getBy2date(aujourdui,aujourdui)">Aujourd'hui</button>-->
        <!--        <button type="button" class="btn btn-info" (click)="getBy2date(hier,hier)">Hier</button>-->
        <!--        <button type="button" class="btn btn-info" (click)="getBy2date(debut_semaine,fin_semaine)">Semaine</button>-->
        <!--        <button type="button" class="btn btn-info" (click)="getBy2date(debut_mois, fin_mois)">Mois</button>-->
        <!--      </div>-->
        <!--      <div class="row periode" style="display: flex; flex-direction: row; margin-top:15px;">-->
        <!--        <div class="col-sm-1">Du:</div>-->
        <!--        <div class="col-sm-3"><input id="date" type="date" value="" name="debut" [(ngModel)]="debut"></div>-->
        <!--        <div class="col-sm-1">A:</div>-->
        <!--        <div class="col-sm-3"><input id="date1" type="date" value="" name="fin" [(ngModel)]="fin"></div>-->
        <!--        <div class="col-sm-2">-->
        <!--          <button class="btn btn-info btn-sm pull-right" (click)="getBy2date(debut,fin)">-->
        <!--            <i class="fa fa-list" aria-hidden="true"></i> Afficher-->
        <!--          </button>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--    <button style="margin-bottom: 15px;" class="btn btn-info btn-sm fichier" color="primary" (click)="generateExcel()">-->
        <!--      Telecharger Excel-->
        <!--    </button>-->
        <div class="row">
            <!-- <table class="table"> -->
            <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngIf="entrepriseType == '1'">Entreprise</th>
                        <th>Date</th>
                        <th>Circuit</th>
                        <th>BOM</th>
                        <th>Chauffeur</th>
                        <th>Ripeur 1</th>
                        <th>Ripeur 2</th>
                        <th>Ripeur 3</th>
                        <th>Localisation début</th>
                        <th>Localisation fin</th>
                        <th>Km initiale</th>
                        <th>Km finale</th>
                        <th>Km collecte</th>
                        <th>Km parcourue</th>
                        <th>Debut</th>
                        <th>Fin</th>
                        <th>Durée de collecte</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listCollect">
                        <td>
                            <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieursCollect(item.collect?.id, $event)">
                        </td>
                        <td *ngIf="entrepriseType == '1'">{{item.collect.entrepriseId != null ? item.collect.entreprise.name:''}}</td>
                        <td>{{item.collect?.createdAt | date:'yyyy-MM-dd'}}</td>
                        <td>{{item.collect?.circuitdebut != null ? item.collect.circuitdebut:'----'}}</td>
                        <!--                      <td>{{item.collect.circuitfin != null ? item.collect.circuitfin:''}}</td>-->
                        <td>{{item.collect?.bomId != null ? item.collect?.bom.immatriculation: '----'}}</td>
                        <td>{{item.collect.driverId != null ? item.collect.driver.lastname+' '+item.collect.driver.firstname:''}}</td>
                        <td>{{item.collect.lastnameripeur1 != null ? item.collect.lastnameripeur1+' '+item.collect.firstnameripeur1 : '----'}}</td>
                        <td>{{item.collect.lastnameripeur2 != null ? (item.collect.lastnameripeur2+' '+item.collect.firstnameripeur2) : '----'}}</td>
                        <td>{{item.collect.lastnameripeur3 != null ? (item.collect.lastnameripeur3+' '+item.collect.firstnameripeur3) : '----'}}</td>
                        <!-- <td>{{item.collect.latitude != null && item.collect.longitude != null ? item.collect.latitude+','+item.collect.longitude:''}}</td>  -->
                        <td><a href="javascript:void(0)" (click)="openMap(item.collect.latitudedebut,item.collect.longitudedebut)">{{item.collect.latitudedebut != null && item.collect.longitudedebut != null ? item.collect.latitudedebut+', '+item.collect.longitudedebut:'----'}}</a></td>
                        <td><a href="javascript:void(0)" (click)="openMap(item.collect.latitudefin,item.collect.longitudefin)">{{item.collect.latitudefin != null && item.collect.longitudefin != null ? item.collect.latitudefin+', '+item.collect.longitudefin:'----'}}</a></td>
                        <td>{{item.collect.kilometrageInitialecoll}}</td>
                        <td>{{item.collect.kilometrageFinalecoll}}</td>
                        <td>{{item.collect.kilometrageFinalecoll != null ? item.collect.kilometrageFinalecoll-item.collect.kilometrageInitialecoll : 0}}</td>
                        <td>
                            <span *ngIf="item?.rapportj">
                              {{ (item?.rapportj?.Kilometragefinal) ?( item?.rapportj?.Kilometragefinal !== null) ? (item?.rapportj?.Kilometragefinal - item?.rapportj?.Kilometrageinitial) : 0 :  "----"}}
                            </span>
                            <span *ngIf="!item.rapportj">
                              ----
                            </span>
                        </td>
                        <!--                    <td>{{item?.rapportJ.Kilometragefinal != null ? (item?.rapportJ.Kilometragefinal - item?.rapportJ.Kilometrageinitial) : 0}}</td>-->
                        <!-- <td>{{item.hourBegin != null ? item.hourBegin : ''}}</td> -->
                        <td>{{item.collect.createdAtBegin != null ? (item.collect.createdAtBegin | date:'HH:mm:ss') : '----'}}</td>
                        <td>{{item.collect.createdAtEnd != null ? (item.collect.createdAtEnd | date:'HH:mm:ss') : '----'}}</td>
                        <!-- <td>{{item.hourEnd != null  ? item.hourEnd : ''}}</td> -->
                        <!--                    <td>{{item.updatedAt == item.createdAt ? '' : item.updatedAt | date:'HH:mm:ss' }}</td>-->
                        <!-- <td>{{item.collect.hourEnd != null ? dateDiff(debut+' '+item.collect.hourBegin, debut+' '+item.collect.hourEnd) : 'en cours'}}</td> -->
<!--                        nesorn koa<td>{{item.collect.hourEnd != null ? dateDiffbycreatedAt(debut+' '+item.collect.hourBegin, debut+' '+item.collect.hourEnd): null}}-->
                        <td>{{item.collect.hourEnd != null ? dateDiffbycreatedAt(item.collect.createdAtBegin, item.collect.createdAtEnd): null}}
                        </td>
                        <!--                    <td>{{item.updatedAt == item.createdAt ? 'en cours' : dateDiff(debut+' '+item.createdAt, debut+' '+item.updatedAt)}}</td>-->
                        <td>
                            <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.collect.id)">
              <i class="fas fa-trash"></i>
            </button>
                        </td>
                    </tr>
                </tbody>
                <!--                      <tbody *ngIf="entrepriseType == '1'">-->
                <!--                      <tr *ngFor="let item of listCollect">-->
                <!--                        <td *ngIf="entrepriseType == '1'">{{item.entrepriseId != null ? item.entreprise.name:''}}</td>-->
                <!--                        <td>{{item?.createdAt | date:'yyyy-MM-dd'}}</td>-->
                <!--                        <td>{{item?.circuitdebut != null ? item.circuitdebut:''}}</td>-->
                <!--                        <td>{{item.bomId != null ? item.bom.immatriculation: ''}}</td>-->
                <!--                        <td>{{item.driverId != null ? item.driver.lastname+' '+item.driver.firstname:''}}</td>-->
                <!--                        <td>{{item.lastnameripeur1 != null ? item.lastnameripeur1+' '+item.firstnameripeur1 : ''}}</td>-->
                <!--                        <td>{{item.lastnameripeur2 != null ? (item.lastnameripeur2+' '+item.firstnameripeur2) : ''}}</td>-->
                <!--                        &lt;!&ndash; <td>{{item.collect.latitude != null && item.collect.longitude != null ? item.collect.latitude+','+item.collect.longitude:''}}</td>  &ndash;&gt;-->
                <!--                        <td><a href="javascript:void(0)"  (click)="openMap(item.latitudedebut,item.longitudedebut)">{{item.latitudedebut != null && item.longitudedebut != null ? item.latitudedebut+', '+item.longitudedebut:''}}</a></td>-->
                <!--                        <td><a href="javascript:void(0)"  (click)="openMap(item.latitudefin,item.longitudefin)">{{item.latitudefin != null && item.longitudefin != null ? item.latitudefin+', '+item.longitudefin:''}}</a></td>-->
                <!--                        <td>{{item.kilometrageInitialecoll}}</td>-->
                <!--                        <td>{{item.kilometrageFinalecoll}}</td>-->
                <!--                        <td>{{item.kilometrageFinalecoll != null ? item.kilometrageFinalecoll-item.kilometrageInitialecoll : 0}}</td>-->
                <!--                        <td>-->
                <!--                            <span  *ngIf="item?.rapportj">-->
                <!--                              {{ (item?.rapportj?.Kilometragefinal) ?( item?.rapportj?.Kilometragefinal !== null) ? (item?.rapportj?.Kilometragefinal - item?.rapportj?.Kilometrageinitial) : 0 :  "&#45;&#45;&#45;&#45;"}}-->
                <!--                            </span>-->
                <!--                          <span *ngIf="!item.rapportj">-->
                <!--                              &#45;&#45;&#45;&#45;-->
                <!--                            </span>-->
                <!--                        </td>-->
                <!--                        <td>{{item.createdAtBegin != null ? (item.createdAtBegin | date:'HH:mm:ss') : ''}}</td>-->
                <!--                        <td>{{item.createdAtEnd  != null ? (item.createdAtEnd | date:'HH:mm:ss') : ''}}</td>-->
                <!--                        <td>{{item.hourEnd != null ? dateDiff(debut+' '+item.hourBegin, debut+' '+item.hourEnd) : 'en cours'}}</td>-->
                <!--                        <td>-->
                <!--                          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">-->
                <!--                            <i class="fas fa-trash"></i>-->
                <!--                          </button>-->
                <!--                        </td>-->
                <!--                      </tr>-->
                <!--                      </tbody>-->
            </table>
        </div>
        <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieursCollect()">
      Supprimer les rapports cochés
    </button>
    </div>
</div>

<div *ngIf="a === true"><span (click)="bb()"> bonjour </span></div>

<ng-template #confirmDelete>
    <div class="card-body">
        Etes-vous sûr de vouloir supprimer?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="deleteCollects()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
</ng-template>

<ng-template #confirmCheckDeletePlsCollect>
    <div class="card-body">
        Êtes-vous sûr de vouloir supprimer ces rapports?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieursCollecte()">OUI</button>
        <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
    </div>
</ng-template>

<ng-template #openCart>
    <div class="card-body">
        <div id="map" style="width:100%;height:400px"></div>
    </div>
</ng-template>
